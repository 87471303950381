import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';

export const toastViewport = (theme: BirdiTheme) => css`
  position: fixed;
  top: 4em;
  right: 2em;
  max-width: 120vw;
  list-style: none;
  outline: none;
`;

export const toastRoot = (theme: BirdiTheme) => css`
  background-color: #EFFBF5;
  border-radius: 15px;
  box-shadow: 2px 0 2px #EAEAEA;
  padding: 0.5em 1.5em;
`;

export const toastContent = (theme: BirdiTheme) => css`
  margin: 0;
  color: ${theme.midnight};
  font-size: 0.85em;
  font-weight: bold;
  display: flex;
  align-items: center;
  svg {
    font-size: 2.2em;
    margin-right: 0.5em;
  }
`;
