import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { BirdiTheme, breakpoints, fonts } from '@birdi/theme';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useMetaSelector } from '../../../meta/reducer';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import Select from 'react-select';
import GeneralSVG from '@birdi/icons/menu-general.svg';
import UsersSVG from '@birdi/icons/menu-users.svg';
import BillingSVG from '@birdi/icons/menu-billing.svg';
import PlanSVG from '@birdi/icons/menu-plan.svg';
import ProcessingSVG from '@birdi/icons/menu-processing.svg';
import CustomizationSVG from '@birdi/icons/menu-customization.svg';
import PermissionsSVG from '@birdi/icons/menu-permissions.svg';

const iconStyle = css`font-size: 1.25em; margin-top: 4px`;

const CustomOption = ({ data, ...props }) => (
  <div {...props.innerProps} style={props.getStyles('option', props)}>
    <div css={css`display: flex; align-items: center;`}>
      {data.icon && <span css={css`margin-right: 1em`}>{data.icon}</span>}
      <span>{data.label}</span>
    </div>
  </div>
);

const CustomSingleValue = ({ data, ...props }) => (
  <div {...props.innerProps} style={props.getStyles('singleValue', props)}>
    <div css={css`display: flex; align-items: center;`}>
      {data.icon && <span css={css`margin-right: 1em`}>{data.icon}</span>}
      <span>{data.label}</span>
    </div>
  </div>
);

export const MobileDrpdownMenu = ({
  title = 'admin',
}) => {
  const history = useHistory();
  const location = useLocation();

  const { pathname } = history.location;
  const userScopeId = pathname.substring(pathname.lastIndexOf('/') + 1);

  const meta = useMetaSelector();
  const customBranding = meta.orgFeatureFlags.includes(FeatureFlagNames.CustomBranding);
  const inspection = meta.orgFeatureFlags.includes(FeatureFlagNames.PowercorInspectionDashboard)
    && meta.permissions.includes('org:manage-missions');

  let options = [];

  switch (title) {
    case 'admin':
      options = [
        {
          value: 'General',
          label: 'General',
          link: '/admin-general',
          icon: <GeneralSVG css={iconStyle} />,
        },
        {
          value: 'Users',
          label: 'Users',
          link: '/admin-users',
          icon: <UsersSVG css={iconStyle} />,
        },
        {
          value: 'Billing',
          label: 'Billing',
          link: '/admin-billing',
          icon: <BillingSVG css={iconStyle} />,
        },
        {
          value: 'Plan & usage',
          label: 'Plan & usage',
          link: '/admin-plan',
          icon: <PlanSVG css={iconStyle} />,
        },
        {
          value: 'Processing',
          label: 'Processing',
          link: '/admin-processing',
          icon: <ProcessingSVG css={iconStyle} />,
        },
        {
          value: 'Customization',
          label: 'Customization',
          link: '/admin-customization',
          icon: <CustomizationSVG css={iconStyle} />,
          isDisabled: !customBranding,
        },
      ];
      break;
    case 'user-profile':
      options = [
        {
          value: 'General',
          label: 'General',
          link: `/profile-general/${userScopeId}`,
          icon: <GeneralSVG css={iconStyle} />,
        },
        {
          value: 'Permissions',
          label: 'Permissions',
          link: `/profile-permissions/${userScopeId}`,
          icon: <PermissionsSVG css={iconStyle} />,
        },
      ];
      break;
    case 'pilot-settings':
      options = [
        {
          value: 'Pilot Profile',
          label: 'Pilot Profile',
          link: '/user/pilot',
        },
        {
          value: 'Credentials',
          label: 'Credentials',
          link: '/user/credentials',
        },
        {
          value: 'Equipment',
          label: 'Equipment',
          link: '/equipment',
        },
      ];
      break;
    case 'inspection-tools':
      options = [
        {
          value: 'Assessment Types',
          label: 'Assessment Types',
          link: '/assessment-types',
        },
        {
          value: 'Assessment Severity',
          label: 'Assessment Severity',
          link: '/assessment-severity',
        },
        {
          value: 'Inspection Summary',
          label: 'Inspection Summary',
          link: '/inspection-summary',
          isDisabled: !inspection,
        },
      ];
      break;
    default:
      options = [];
      break;
  }

  const [selectedOption, setSelectedOption] = useState(options[0]);
  useEffect(() => {
    const selected = options.find((option) => location.pathname.includes(option.link)) || options[0];
    setSelectedOption(selected);
  }, [location.pathname]);


  return (
    <div css={css`margin: 1em`}>
      <Select
        isSearchable={false}
        onChange={(option) => history.push(option.link)}
        value={selectedOption}
        placeholder="Select ..."
        options={options}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            height: '100%',
            width: '100%',
            fontFamily: fonts.display,
            borderRadius: '7px',
            fontWeight: 'bold'
          }),
          // option: (provided, state) => ({
          //   ...provided,
          //   backgroundColor: state.isSelected ? '#2287FA' : 'white',
          //   color: state.isSelected ? 'white' : '38385C',
          //   fontWeight: 'bold',
          //   fontFamily: fonts.display,
          //   '&:hover': {
          //     backgroundColor: state.isSelected ? '#2287FA' : '#EBF4FF',
          //   }
          // }),
        }}
      />
    </div>
  );
};
