import { css } from '@emotion/react';
import { MissionCard } from '@birdi/mission-kit/Card';
import { MissionContextMenu } from './ContextMenu';
import { cardList } from '@birdi/theme/blocks';
import { CreateMissionCard, EmptyCard } from '../dashboard/create-mission-card';
import { useMetaSelector } from '../meta/reducer';

export interface MissionListProps {
  missions: any[];
  deleteMission?: (id: string) => void;
  isDashboard?: boolean;
}

export const MissionList = ({
  missions,
  deleteMission,
  isDashboard = false,
}: MissionListProps) => {

  let missionItems = missions;
  if (isDashboard) {
    missionItems = [ ...missionItems, 'create map card' ];
    if (missionItems.length < 6) {
      while (missionItems.length < 6) missionItems = [ ...missionItems, 'empty card' ];
    }
  }

  // TODO: probably restrict behind a feature flag, rather than ops.
  const { opsActions } = useMetaSelector();
  const isOps = opsActions.includes('access_ops');

  return (
    <div css={cardList}>
      {missionItems.map((mission, index) => {
        if (isDashboard && mission === 'create map card') {
          return <CreateMissionCard key={index} />
        } else if (isDashboard && mission === 'empty card') {
          return <EmptyCard key={index} />
        } else {
          return (
            <MissionCard
              key={index}
              mission={mission}
              href={`${process.env.MAP_APP_URL}/missions/${mission.id}`}
              v2Href={isOps ? `${process.env.MAP_APP_URL}/next/${mission.id}` : undefined}
              ContextMenu={(contextProps) => (
                <MissionContextMenu
                  mission={mission}
                  deleteMission={deleteMission}
                  {...contextProps}
                />
              )}
            />
          )
        }
      })}
    </div>
  );
};
