import {  withRouter } from 'react-router-dom';
import cx from 'classnames';
import { css } from '@emotion/react';
import { PoweredByBirdi } from '@birdi/branding/powered-by-birdi';
import {
  SideBar, SidebarLink, dividerStyle,
  TitleHolder, TitleStyle, poweredByBirdiStyle, BackButton,
} from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';

const SideNavigationProfilePrev = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const jobNotif = meta.jobNotifications;
  return (
    <div css={css`position: relative;`}>
      <SideBar>
        <BackButton label="My Profile" id="my-profile"/>
        <hr css={dividerStyle} />
        <div css={TitleHolder}>
          <span css={TitleStyle}>Account Settings</span>
        </div>
        <SidebarLink to="/user/details" match={history.location.pathname}>
          <span>Personal Info</span>
        </SidebarLink>
        <hr css={dividerStyle} />
        {/* {jobNotif && meta.location.country === 'Australia' && (
          <> */}
            <div css={TitleHolder}>
              <span css={TitleStyle}>Pilot Network</span>
            </div>
            <SidebarLink to="/user/pilot" match={history.location.pathname}>
              <span>Pilot Profile</span>
            </SidebarLink>
            <SidebarLink to="/user/credentials" match={history.location.pathname}>
              <span>Credentials</span>
            </SidebarLink>
            <SidebarLink to="/equipment" match={history.location.pathname}>
              <span>Equipment</span>
            </SidebarLink>
            <hr css={dividerStyle} />
          {/* </>
        )} */}
      </SideBar>
      <div css={poweredByBirdiStyle}>
        <PoweredByBirdi mode="dark" />
      </div>
    </div>
  )
});

export default SideNavigationProfilePrev;
