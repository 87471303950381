import React, { ReactElement } from 'react';
import { css } from '@emotion/react';
import { itemsTable } from '@birdi/theme/blocks';
import { Link } from 'react-router-dom';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import { BirdiTheme } from '@birdi/theme';
import { useMatchWidth } from '@birdi/shared-deprecated/match-width';
import { breakpoints } from '@birdi/theme';

export interface TableItemFormat {
  title: string,
  value: string,
  internalUrl?: string | null,
  externalUrl?: string,
  component?: ReactElement,
  laptopOnly?: boolean,
  mobileOnly?: boolean,
  linkUnderLined?: boolean,
}

interface DataProps {
  data: Array<Array<TableItemFormat>>,
  tableStyles: any,
}

interface SharedPartProps {
  rowItem: TableItemFormat,
  mobile?: boolean,
}

const SharedPart = ({ rowItem, mobile = false }: SharedPartProps) => (
  <div>
    {mobile && rowItem.title && (
      <span css={css`font-weight: bold; margin-right: 0.5em`}>{rowItem.title}:</span>
    )}
    {!rowItem.internalUrl ? (
      <span>{rowItem.value}</span>
    ) : (
      <Link to={rowItem.internalUrl}>
        <span css={(theme) => css`
          text-decoration: ${mobile || rowItem.linkUnderLined ? 'underline' : 'none'};
          color: ${theme.mono98};
          `}
        >
          {rowItem.value}
        </span>
      </Link>
    )}
    {rowItem.externalUrl && (
      <a
        href={rowItem.externalUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ExternalLinkSVG css={(theme: BirdiTheme) => css`margin-left: 0.5em; color: ${theme.mono98}`} />
      </a>
    )}
    {rowItem.component && React.createElement(rowItem.component.type, rowItem.component.props)}
  </div>
);

export const ResponsiveTable = ({ data, tableStyles }: DataProps) => {
  const mobile = useMatchWidth(breakpoints.xs);
  if (mobile) {
    return (
      <div css={css`display: flex; flex-direction: column;`}>
        {data.map((dataItem, dataIndex) => (
          <div
            key={dataIndex}
            css={(theme) => css`
              display: flex;
              flex-direction: column;
              gap: 0.75em;
              padding: 1em;
              border: solid 1px ${theme.mono20};
              border-radius: 3px;
              box-shadow: 1px 2px 5px ${theme.mono20};
              margin-bottom: 1em;
            `}
          >
            {dataItem.map((item, index) => !item.laptopOnly && (
              <div key={index}>
                <SharedPart rowItem={item} mobile />
              </div>
            ))}
          </div>
        ))}
      </div>
    )
  } else {
    return (
      <div css={css`overflow-x: auto; overflow-y: auto;`}>
        <table css={(theme) => css`${itemsTable(theme)}; min-width: 500px; ${tableStyles}`}>
          <thead>
            <tr>
              {data.length && data[0].filter((info) => (!info.mobileOnly)).map((dataItem, index) => (
                <th key={index}>{dataItem.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((dataItem, dataIndex) => (
              <tr key={dataIndex}>
                {dataItem.map((item, index) => !item.mobileOnly && (
                  <td key={index}>
                    <SharedPart rowItem={item} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};
