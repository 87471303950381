import { css, Theme } from '@emotion/react';

const base = (theme: Theme) => css`
  height: 36px;
  width: 100%;
  padding: 0 0.5rem;
  font-size: 1rem;
  border-radius: 6px;
  resize: vertical;
  border: solid 1px ${theme.mono40};
  color: ${theme.midnight};
  outline-color: ${theme.ocean};

  &,
  & *,
  & *::before,
  & *::after {
    box-sizing: border-box;
  }

  &:disabled {
    color: ${theme.midGrey};
    border-color: ${theme.midGrey};
  }
`;

export const text = base;

export const textarea = (theme: Theme) => css`
  ${base(theme)};
  height: unset;
  padding: 0.25rem 0.5rem;
`;

// TODO: pre-built components using this
export const select = {
  container: css`
    position: relative;
  `,
  select: base,
  icon: css`
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    pointer-events: none;
  `,
};

export const input = {
  container: css`
    position: relative;
    display: flex;
    align-items: center;
  `,
  input: (theme: Theme) => css`
    ${base(theme)};
    height: 1.5rem;
    text-align: right;
  `,
  symbol: (theme: Theme) => css`
    position: absolute;
    right: 0.3rem;
    pointer-events: none;
  `,
  slider: (theme: Theme) => {
    const thumbColor = theme.deepOcean;
    const hoverThumbColor = theme.ocean;
    const thumbSize = '0.6rem';
    const trackHeight = '0.3rem';
    const borderRadius = '0.15rem';
    const thumbMargin = `-0.15rem`;

    const sliderGradient = `
      linear-gradient(
        to right,
        blue 0%, blue 0.5%,
        ${theme.mono30} 0.5%, ${theme.mono30} 24%,
        ${theme.ocean} 24%, ${theme.ocean} 24.5%,
        ${theme.mono30} 24.5%, ${theme.mono30} 50%,
        ${theme.ocean} 50%, ${theme.ocean} 50.5%,
        ${theme.mono30} 50.5%, ${theme.mono30} 74%,
        ${theme.ocean} 74%, ${theme.ocean} 74.5%,
        ${theme.mono30} 74.5%, ${theme.mono30} 99%,
        ${theme.ocean} 99%, ${theme.ocean} 99.5%
      )
    `;

    const thumbStyles = `
      height: ${thumbSize};
      width: ${thumbSize};
      background-color: ${thumbColor};
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      margin-top: ${thumbMargin};
    `;

    return css`
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      height: 1.5rem;

      /* WebKit (Chrome, Safari) */
      &::-webkit-slider-runnable-track {
        background: ${sliderGradient};
        height: ${trackHeight};
        border-radius: ${borderRadius};
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        ${thumbStyles}
      }

      &:hover::-webkit-slider-thumb,
      &:active::-webkit-slider-thumb {
        -webkit-appearance: none;
        background-color: ${hoverThumbColor};
      }

      /* Firefox */
      &::-moz-range-track {
        background: ${sliderGradient};
        height: ${trackHeight};
        border-radius: ${borderRadius};
      }

      &::-moz-range-thumb {
        ${thumbStyles}
      }

      &:hover::-moz-range-thumb,
      &:active::-moz-range-thumb {
        background-color: ${hoverThumbColor};
      }
    `;
  }
};
