import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { formating } from './utils';
import { ResponsiveTable, TableItemFormat } from '../common/responsive-table';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import { useMetaSelector } from '../meta/reducer';
import { itemsTable } from '@birdi/theme/blocks';

const tableHolderStyles = (theme: BirdiTheme) => css`
  flex-grow: 1;
  padding: 1em 0;
  border: solid 1px ${theme.mono20};
  border-radius: 10px;
  @media (max-width: ${breakpoints.xs}) {
    border: none;
  };
`;
const insideTableStyles = css`
  tr { border: none }
  th { padding: 0 1em 1em 1em }
  td { padding: 1em 1em 0 1em }
`

export const JobTable = ({ jobs }) => {
  const meta = useMetaSelector();

  const headers = ['Map name', 'Status', 'GP', 'Cost', 'Requested by', 'Created date']; // 'GCP requested'

  if (!jobs || !jobs.length) {
    return(
      <div css={tableHolderStyles}>
        <div css={css`overflow-x: auto; overflow-y: auto;`}>
          <table css={(theme) => css`${itemsTable(theme)}; min-width: 500px; ${insideTableStyles}`}>
            <thead>
              <tr>
                {headers.map((item, index) => {
                  if (meta.organisation.plan === 'enterprise' && item === 'Cost') {
                    return;
                  } else {
                    return (
                      <th key={index}>{item}</th>
                    )
                  }
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={6}>
                  <p>You currently do not have any processing jobs to display.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const prepareFormatedData = (item) => [
    {
      title: 'Map name',
      value: item.mapName,
      internalUrl: `map/missions/${item.mapId}`,
      linkUnderLined: true,
    },
    {
      title: 'Status',
      value: '',
      component: (
        <div css={(theme: BirdiTheme) => css`
          background-color: ${item.status === 'complete' ? theme.lightFlora : (item.status === 'failed' ? theme.lightCanary : theme.lightOcean)};
          color: ${item.status === 'complete' ? theme.flora : (item.status === 'failed' ? theme.canary : theme.deepOcean)};
          padding: 0.5em;
          border-radius: 4px;
          max-width: 4em;
          text-align: center;
        `}>
          {item.status}
        </div>
      )
    },
    {
      title: 'GP',
      value: item.gp,
    },
    {...(meta.organisation.plan !== 'enterprise') && {
      title: 'Cost',
      value: item.cost,
    }},
    {
      title: 'Requested by',
      value: item.requestedBy,
    },
    {
      title: 'Created date',
      value: item.createdDate,
    },
    // {...(meta.organisation.plan === 'pro') && {
    //   title: 'GCP requested',
    //   value: item.gcp,
    // }},
  ];
  const data = jobs.map((job) => formating(job));
  const formatedData = data.map((item) => prepareFormatedData(item));

  return (
    <div css={tableHolderStyles}>
      <ResponsiveTable data={formatedData} tableStyles={insideTableStyles} />
    </div>
  )
};
