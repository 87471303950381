import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { Date } from './Date';
import { MarketplaceStatus } from './MetaData';
import { format, parseISO } from 'date-fns';
import { CoverGridObserver, getCover } from './CoverImage';
import { Mission } from '@birdi/types/mission';

/* Enabling focus styles on keyboard only
 * https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press
*/
const cardCss = (theme) => css`
  display: inline-block;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 3px 3px 10px ${theme.mono30};
  border: solid 1px ${theme.mono20};
  transition: 0.15s shadow, 0.15s transform;
  cursor: pointer;
  width: 100%;
  margin: 0 0.1rem 1rem 0;
  @media (min-width: ${breakpoints.xs}) {
    width: calc((100% / 2) - 1rem);
    margin: 0 1rem 1rem 0;
  }
  @media (min-width: ${breakpoints.s}) {
    width: calc((100% / 3) - 1rem);
  }
  &:focus, & > span:focus {
    outline: 0;
  }
  &:focus > span  {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

export const MissionCardLoading = () => (
  <div css={css`
    display: inline-block;
    width: 100%;
    margin: 0 1.1rem 1.1rem 0;
    @media (min-width: ${breakpoints.xs}) {
      width: calc((100% / 2) - 1.1rem);
    }
    @media (min-width: ${breakpoints.s}) {
      width: calc((100% / 3) - 1.1rem);
    }
  `}
  >
    <div css={(theme) => css`
      background: ${theme.mono25};
      height: 12.5rem;
      width: 100%;
      border-radius: 2px;
    `}
    />
    <div css={(theme) => css`background: ${theme.mono25}; margin-top: 1em; height: 1.1em; width: 40%; border-radius: 1px;`} />
    <div css={(theme) => css`background: ${theme.mono25}; margin-top: 1em; height: 1.1em; width: 50%; border-radius: 1px;`} />
    <div css={(theme) => css`background: ${theme.mono25}; margin-top: 1em; height: 1.1em; width: 30%; border-radius: 1px;`} />
    <div css={(theme) => css`background: ${theme.mono25}; margin-top: 1em; height: 1.1em; width: 30%; border-radius: 1px;`} />
  </div>
);

export interface MissionCardProps {
  mission: Mission & {
    coverImageURL?: string;
    dateType?: string;
    pilotId?: string;
  };
  // handleCardClick: () => void;
  href: string;
  v2Href?: string;
  ContextMenu: (props: Record<string, any>) => React.ReactNode;
}

export const MissionCard = ({ mission, href, v2Href, ContextMenu }: MissionCardProps) => {
  const missionCardRef = useRef<HTMLAnchorElement>();
  const observer = useRef<IntersectionObserver>();
  const [mouseOver, setMouseOver] = useState(false);
  const [coverUrl, setCoverUrl] = useState('');

  const getAndSetCover = useCallback(async () => {
    const newCover = await getCover(mission.id);
    setCoverUrl(newCover);
  }, []);

  useEffect(() => {
    if (!observer.current) {
      const obs = observer.current = CoverGridObserver(getAndSetCover);
      if (missionCardRef.current) obs.observe(missionCardRef.current);
      else console.warn('created mission card observer but no element to observe!!');
    }
  }, []);

  return (
    <a
      ref={missionCardRef}
      tabIndex={0}
      css={cardCss}
      id={mission.id}
      onMouseOver={() => !mouseOver && setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      href={href}
    >
      <span tabIndex={-1}>
        <div
          className="card-cover"
          css={css`
            position: relative;
            height: 12.5rem;
            background-color: #f5f5f5;
            background-size: cover;
            background-position: center;
            text-align: center;
            border-radius: 2px;
            background-image: url('${mission.coverImageURL || coverUrl}');
          `}
        >
          <div css={css`position: absolute; top: 0.5rem; right: 0.5rem;`}>
            {mission.type === 'marketplace' && <MarketplaceStatus status={mission.status} pilot={mission.pilotId} />}
          </div>
        </div>
        <div
          className="card-body"
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 1em;
            padding-bottom: 0.5em;
            height: 7.5em;
          `}
        >
          {ContextMenu && (
            <div
              className="context-menu"
              css={css`position: absolute; top: 0.5em; right: 0.5em;`}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <ContextMenu visible={mouseOver} />
            </div>
          )}
          {false && v2Href && (
            <a
              aria-label="View on the new map"
              title="View on the new map"
              href={v2Href}
              css={theme => css`
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: absolute;
                bottom: 0.5em;
                right: 0.5em;
                background: ${theme.sand};
                width: 2rem;
                height: 2rem;
                font-weight: 700;
                border-radius: 4px;
                color: ${theme.midnight} !important;
              `}
            >
              v2
            </a>
          )}
          <h3
            data-testid="name"
            css={css`
              text-transform: capitalize;
              margin: 0;
              height: 2.5em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: calc(100% - 2em);
            `}
          >
            {mission.name || 'Untitled Map'}
          </h3>
          <div
            css={(theme) => css`
              p {
                color: ${theme.mono60};
                font-size: 0.9em;
                margin: 0.25rem 0;
                // line-height: 1.5em;s
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 2em);
              }
            `}
          >
            <p data-testid="location">
              <span css={css`font-weight: bold;`}>
                Location:&nbsp;
              </span>
              {/* {normalisedRegionText(mission.location)} */}
              {mission.location ? mission.location.name : '-'}
            </p>
            <p data-testid="file-count">
              {
                mission.mediaCount ?
                `${mission.mediaCount} file${mission.mediaCount > 1 ? 's' : ''}` :
                '0 files'
              }
            </p>
            <p>
              {
                mission.flyDate ?
                <span>
                  <Date
                    date={mission.flyDate}
                    dateType={mission.dateType}
                  />
                </span>
                :
                <span>
                  Created {mission.createdAt ? format(parseISO(mission.createdAt), "dd MMM yyyy") : '-'}
                </span>
              }
            </p>
          </div>
        </div>
      </span>
    </a>
  );
};
