import { css, Theme } from '@emotion/react';
import { ThemeConfig } from 'react-select';
import { BirdiTheme, themeDark, themeLight } from './theme';
import { breakpoints, shadows } from './variables';

export const planOptionsColumn = (theme: BirdiTheme) => `
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  span {
    display: block;
    text-align: center;
  }
  @media (max-width: ${breakpoints.xs}) {
    padding-bottom: 1.5em;
    border-bottom: solid 1px ${theme.mono30};
  }
  .option-header {
    color: ${theme.mono60};
    font-weight: bold;
    padding-bottom: 1em;
  }
`;

export const planOptions = (theme: BirdiTheme) => `
  padding: 0.5em;
  border: solid 1px ${theme.mono30};
  border-radius: 1em;
  box-shadow: 2px 0 2px #EAEAEA;
  &:hover {
    border: solid 1px ${theme.deepOcean};
  }
`;

export const planSections = (theme: BirdiTheme) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const planSection1 = (theme: BirdiTheme) => `
  ${planSections(theme)};
  min-height: 19em;
`;
export const planSection2 = (theme: BirdiTheme) => `
  ${planSections(theme)};
  min-height: 6em;
`;
export const planSection3 = (theme: BirdiTheme) => `
  ${planSections(theme)};
  min-height: 4em;
`;
export const planSection4 = (theme: BirdiTheme) => `
  display: flex;
  flex-direction: column;
  margin: 1em;
  min-height: 28em;
  @media (max-width: ${breakpoints.xs}) {
    min-height: 10em;
  }
`;

export const planTitleHolder = (color: string) => `
  padding: 0.5em 1.5em;
  margin: 0.5em 0 1em 0;
  border-radius: 0.75em;
  background-color: ${color};
`;

export const planTitle = (theme: BirdiTheme) => `
  font-size: 1.25em;
  font-weight: bold;
  text-transform: capitalize;
`;

export const planSubTitle = (theme: BirdiTheme) => `
  ${planTitle(theme)};
  text-transform: none;
  margin-top: 1em;
`;

export const currentPlanTitle = (theme: BirdiTheme) => `
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 12em;
  height: 2.5em;
  margin: 1em;
  font-weight: bold;
  color: ${theme.midnight};
  border: solid 1px ${theme.flora};
  background-color: ${theme.lightFlora};
  border-radius: 0.5em;
`;

export const itemsTable = (theme: BirdiTheme) => `
width: 100%;
text-align: left;
thead tr {
  padding: 1em;
  border-bottom: 1px solid ${theme.mono20};
}
tr {
  padding: 1em;
  font-weight: 400;
  border-bottom: 1px solid ${theme.mono20};
}
td, th {
  padding: 2em 1em;
}
a {
  color: ${theme.mono70};
}
`;

export const itemsNewTable = (theme: BirdiTheme) => `
width: 100%;
text-align: left;
thead tr {
  border-bottom: 1px solid ${theme.mono20};
}
tr {
  font-weight: 400;
  border-bottom: 1px solid ${theme.mono20};
}
td {
  padding: 1.25em 2em;
}
th {
  padding: 0.75em 2em;
}
a {
  color: ${theme.midnight};
}
`;

export const input = (theme: BirdiTheme) => `
box-sizing: border-box;
border: 1px solid ${theme.mono30};
border-radius: 2px;
font-size: 1rem;
padding: 0.75rem;
background: none;
`;

export const textarea = (theme) => `
  background: none;
  font-size: 1em;
  border: 1px solid ${theme.mono20};
`;

/**
 *
 * @deprecated
 */
export const formStyles = (theme) => `
select {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
input:not([type='radio']):not([type='checkbox']):not([hidden]), textarea {
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid ${theme.mono40};
  margin-bottom: 1rem;
}
h2 {
  margin: 1.25em 0 0.25em 0;
  font-size: 1rem;
  font-weight: normal;
}
`;

export const blockLabel = css`display: block;`;

/**
 * @deprecated
 */
export const button = (theme) => css`
  border-radius: 2px;
  padding: 0.5em 0.75em;
  font-size: 1rem;
  color: white;
  background: ${theme.deepOcean};
  white-space: nowrap;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  &:active {
    color: white;
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  &:disabled {
    cursor: not-allowed;
    background: ${theme.mono50};
  }
  svg {
    margin-right: 0.25rem;
  }
  &:focus {
    outline: 2px solid ${theme.lightOcean};
  }
`;

/**
 * @deprecated
 */
export const newButton = (theme) => css`
  ${button(theme)};
  background: ${theme.deepOcean};
  padding: 0.75em;
  min-width: 7em;
  font-weight: 700;
  &.small {
    font-size: 0.75rem;
    padding: 0.5em;
  }
`;

/**
 * @deprecated
 */
export const buttonTransparent = (theme) => css`
  ${newButton(theme)};
  background-color: transparent;
  color: ${theme.deepOcean};
  :focus {
    outline: none;
  }
  :active {
    color: ${theme.deepOcean};
  }
  :hover {
    box-shadow: none;
  }
`;

/**
 * @deprecated
 */
export const buttonOutlined = (theme) => css`
  border-radius: 2px;
  padding: 0.75rem;
  font-size: 1rem;
  color: inherit;
  border: 1px solid ${theme.mono20};
  background: ${theme.mono0};
  white-space: nowrap;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  &:disabled {
    cursor: not-allowed;
    background: ${theme.mono20};
  }
  svg {
    margin-right: 0.25rem;
  }
`;

/**
 * @deprecated
 */
export const buttonOutlinedBlue = (theme) => css`
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: ${theme.ocean};
  border: 1px solid ${theme.ocean};
  background: transparent;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-weight: bold;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  svg {
    margin-right: 0.25rem;
  }
`;

export const cardCSS = `
  display: inline-block;
  box-sizing: border-box;
  margin: 0 2rem 2rem 0;
  box-shadow: rgba(9,30,66,0.1) 0px 1px 1px, rgba(9,30,66,0.2) 1px 2px 7px -2px;
  border-radius: 4px;
  transition: 0.15s shadow, 0.15s transform;
  cursor: pointer;
  width: 100%;
  @media (min-width: ${breakpoints.xs}) {
    width: calc((100% / 2) - 2rem);
  }
  @media (min-width: ${breakpoints.s}) {
    width: calc((100% / 3) - 2rem);
  }
  &:focus, & > span:focus {
    outline: 0;
  }
  &:focus > span  {
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

export const reactSelectLight: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    // https://react-select.com/styles#overriding-the-theme
    ...theme.colors,
    primary25: themeLight.lightOcean,
    // primary50: themeLight.mono70,
    neutral0: themeLight.mono0,   // background
    neutral5: themeLight.mono20,
    neutral10: themeLight.mono20,
    neutral20: themeLight.mono40, // border, elements (non-focused)
    neutral30: themeLight.mono40,
    neutral40: themeLight.mono40,
    neutral50: themeLight.mono50, // placeholder text colour
    neutral60: themeLight.mono70,
    neutral70: themeLight.midnight,
    neutral80: themeLight.midnight,
    neutral90: themeLight.midnight,
  },
});

export const link = css`
  color: inherit;
  text-decoration: underline;
  padding: 0;
`;

export const linkPrimary = (theme) => css`
  ${link};
  color: ${theme.deepOcean};
`

export const contentLimit = `
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const contextualHeader = (theme) => `
position: relative;
z-index: 3;
display: flex;
justify-content: flex-end;
align-items: center;
flex-shrink: 0;
box-sizing: border-box;
color: ${theme.midnight};
background: ${theme.mono0};
width: 100%;
padding: 0.5rem 1rem;
font-weight: 500;
box-shadow: ${shadows.header};
`;

export const selectContainer = (theme) => css`
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 9em;
  max-width: 100%;
  &:not(:last-child) {
    margin-right: 0.5em;
  }
  select {
    float: right;
    position: relative;
    width: 100%;
    padding: 1em;
    z-index: 1;
    font-size: 1rem;
    border: 1px solid ${theme.mono30};
    background: none;
  }
  option {
    color: black;
  }
  svg {
    height: 1rem;
    width: 1rem;
    z-index: 0;
    position: absolute;
    right: 0;
    top: 0.5em;
    padding: 1rem;
    stroke-width: 3px;
    transform: rotateZ(90deg);
  }
`;

export const separator2 = (theme) => `
  display: flex;
  border-bottom: 1px solid ${theme.mono20};
  padding: 1em;
`;

export const separator = (theme) => `
  display: flex;
  border-bottom: 1px solid ${theme.mono20};
  border-top: 1px solid ${theme.mono20};
  padding: 1em;
  ${contentLimit};
`;

export const reactSelectDarkTheme: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    // https://react-select.com/styles#overriding-the-theme
    ...theme.colors,
    primary25: themeDark.mono20,
    primary50: themeDark.mono70,
    neutral0: themeDark.globalBackground, // background
    neutral5: themeDark.mono20,
    neutral10: themeDark.mono20,
    neutral20: themeDark.mono20, // border, elements (non-focused)
    neutral30: themeDark.mono40,
    neutral40: themeDark.mono40,
    neutral50: themeDark.mono40,
    neutral60: themeDark.mono70,
    neutral70: themeDark.mono98,
    neutral80: themeDark.mono98,
    neutral90: themeDark.mono98,
  },
});

// The new dark theme for the select. Currently used only in the assessments page
export const reactSelectDarkThemeAssessments: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    // https://react-select.com/styles#overriding-the-theme
    ...theme.colors,
    primary: themeDark.mono40, // border, elements (focused)
    primary25: themeDark.mono60, // hover/selected options
    neutral0: themeDark.mono0, // background
    neutral10: themeDark.mono60, // selected option bg in multi-select
    neutral20: themeDark.mono40, // border, elements (non-focused)
    neutral30: themeDark.mono40, // border, elements (hover)
    neutral80: themeDark.mono98, // Text color of selected
  },
});

export const visuallyHidden = `
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const searhContainer = css`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
`;

export const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding: 1rem;
`;

export const listSearchForm = css`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`;

export const listContainer = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-sizing: border-box;
  padding: 1.5rem;
  border-top: solid 1px ${theme.mono30};
`;

export const cardList = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const overlayMenuContainerCSS = `
  position: relative;
  .drop-menu-enter {
    opacity: 0.01;
    transform: scale(0.95) translateY(-5px);
  }
  .drop-menu-enter-active {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: all 150ms ease-out;
  }
  .drop-menu-exit {
    opacity: 1;
    transform: scale(1) translateY(0%);
  }
  .drop-menu-exit-active {
    opacity: 0.01;
    transform: scale(0.95) translateY(-5px);
    transition: all 150ms ease-out;
  }
`;

export const contextMenuStyle = (theme) => css`
  position: absolute;
  top: calc(100% - 0.5rem);
  right: 2rem;
  width: 15rem;
  background-color: ${theme.mono0};
  border-radius: 2px;
  box-shadow: rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.16) 0px 0px 1px 0px;
  > * {
    display: block;
    width: 100%;
    padding: 1rem 2rem;
    text-align: left;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    &:hover:enabled {
      background: ${theme.mono20}
    }
  }
`;

// TODO: Deprecate one of these
export const errorMessage = css`
  display: block;
  padding-top: 0.5rem;
  color: red;
`;

export const errorStyle = css`
  display: block;
  margin-bottom: 1em;
  color: red;
  font-weight: 300;
`;

export const tooltip = `
  background: black;
  color: white;
  position: absolute;
  text-align: center;
  padding:0.5rem;
  border-radius: 3px;
  z-index: 2;
  &:after{
    content:'';
    position:absolute;
    bottom:-0.5rem;
    width:0.7rem;import { css } from '@emotion/react';
    height:0.7rem;
    border-bottom:0.3rem solid black;
    border-right:0.3rem solid black;
    background:black;
    left:50%;
    margin-left:-1rem;
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
  }`;

export const mapTooltip = `
  background: black;
  color: white;
  position: absolute;
  text-align: center;
  padding:0.5rem;
  border-radius: 3px;
  z-index: 2;
  &:after{
    content:'';
    position:absolute;
    top:-0.5rem;
    width:0.7rem;
    height:0.7rem;
    border-bottom:0.3rem solid black;
    border-right:0.3rem solid black;
    background: black;
    left:50%;
    margin-left:-1rem;
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
  }`;

// https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
export const noSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
`;

export const buttonHolder = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 0.5rem;
`;

export const textSecondary = (theme: BirdiTheme) => css`
  font-size: 0.875rem;
  color: ${theme.mono50};
`;

export const scrollbar = (width = 16) => (theme: BirdiTheme) => css`
&::-webkit-scrollbar {
  width: ${width}px;
}
&::-webkit-scrollbar-track {
  background-color: transparent;
}
&::-webkit-scrollbar-thumb {
  background-color: ${theme.mono40};
  border-radius: ${width}px;
  border: 4px solid transparent;
  background-clip: padding-box;
}`;

export const pageDescription = (theme: BirdiTheme) => `
  background-color: ${theme.sand};
  margin: 0.5em;
  padding: 1em;
  border-radius: 3px;
  p { margin: 0.5em 0 }
  div {
    display: flex;
    flex-direction: row;
    a, button {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        margin: 0 2em 0 0.5em;
        color: ${theme.deepOcean};
        font-size: 0.85em;
      }
    }
  }
`;

export const pageTitleHolder = css`
  height: 3.5em; // padding-top is already 1em
  display: flex;
  align-items: center;
  margin: 4px 0 0 0;
  display: none;
  @media (min-width: ${breakpoints.m}) {
    display: flex;
  }
`;

export const pageTitle = css`
  padding: 0;
  margin: 0;
  margin-left: 1em;
`;

export const pageGraySection = (theme: BirdiTheme) => `
  background-color: ${theme.sand};
  padding: 0 1em;
  max-height: 3.5em;
  margin-top: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  p {
    margin-right: 1em;
  }
  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
    max-height: 10em;
  }
`;
