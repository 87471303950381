import { css, Theme } from '@emotion/react';

const base = (theme: Theme) => css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  height: 36px;
  border-radius: 6px;
  font-weight: 700;
  padding: 0 20px;
  transition: box-shadow 0.2s;
  border: none;

  &,
  & *,
  & *::before,
  & *::after {
    box-sizing: border-box;
  }

  &:hover:enabled {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 2px solid ${theme.ocean};
  }
`;

export const primary = (theme: Theme) => css`
  ${base(theme)};
  background: ${theme.deepOcean};
  color: ${theme.mono0};
  border: solid 1px ${theme.deepOcean};

  &:disabled {
    background: ${theme.mono0};
    color: ${theme.mono40};
    border: solid 1px ${theme.mono40};
  }

  &:focus {
    outline: 2px solid ${theme.midnight};
  }
`;

export const secondary = (theme: Theme) => css`
  ${base(theme)};
  background: ${theme.mono0};
  color: ${theme.deepOcean};
  border: solid 1px ${theme.deepOcean};

  &:disabled {
    background: ${theme.mono0};
    border: solid 1px ${theme.mono40};
    color: solid 1px ${theme.mono40};
  }
`;

export const icon = (theme: Theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: ${theme.midnight};

  &,
  & *,
  & *::before,
  & *::after {
    box-sizing: border-box;
  }

  &:disabled {
    color: ${theme.mono40};
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 2px solid ${theme.ocean};
  }
`;
