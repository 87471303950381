/* globals window */

import React, { useEffect, useState } from 'react';
import { stroot, subscription as subscriptionAPI, organisation as orgAPI } from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import FreePlanSVG from '@birdi/icons/free-plan-2.svg';
import PlusPlanSVG from '@birdi/icons/plus-plan-2.svg';
import GrowthPlanSVG from '@birdi/icons/growth-plan-2.svg';
import EnterprisePlanSVG from '@birdi/icons/ultimate-plan-2.svg';
import { BirdiTheme } from '@birdi/theme';
import { useMetaSelector } from '../../meta/reducer';
import { CurrentPlan } from '../types';
import { PlanChangedModal } from './plan-changed-modal';
import { pageGraySection } from '@birdi/theme/blocks';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ManageSeatsButton } from '../add-seats-modal';
import history from '../../state/history';
import { useDispatch } from 'react-redux';
import { useSeatsSelector, loadSeats, totalSeats } from '../seats-reducer';
import { LoadingSpinner } from '@birdi/loading';

const loadingIcon = <LoadingSpinner color="#2287FA" size="1em" />;

interface StorageData {
  storageGb: number;
  mediaCount: number;
  mediaGb: number;
}

const manageButtonStyle = (theme: BirdiTheme) => css`
  color: ${theme.deepOcean};
  margin: 0;
  padding: 0;
  span {
    text-decoration: underline;
  };
  &:disabled {
    color: ${theme.mono40};
    cursor: default;
  }
`;

// TODO: Improve separation of externally billed vs internally billed component
export const PlanAndUsage = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | null>(null);
  const [planChangedModal, setPlanChangedModal] = useState<boolean>(false);
  const [oldPlan, setOldPlan] = useState('');
  const [cancellingPlan, setCancellingPlan] = useState('no');
  const meta = useMetaSelector();
  const seats = useSeatsSelector();

  const [storageData, setStorageData] = useState<StorageData | null>(null);
  const [processingData, setProcessingData] = useState(null);

  const [seatsModal, setSeatsModal] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = searchParams.get('session_id');
    const oldPlan = searchParams.get('old_plan');
    if (sessionId) {
      // TODO: This will race with a webhook, but should return 200 regardless
      subscriptionAPI.saveSubscriptionData(stroot('poobu2'), sessionId)
        .then((res) => {
          localStorage.setItem('localOldPlan', oldPlan || 'free');
          window.location.replace(location.pathname);
        });
    } else {
      if (!seats.loaded) loadSeats(dispatch);
      orgAPI.getStorageInfo(stroot('faci1a'))
        .then((res) => {
          setStorageData({
            mediaGb: res.body?.mediaGb,
            storageGb: res.body ? (res.body.storageGb >= 1000 ? `${res.body.storageGb / 1000}TB` : `${res.body.storageGb}GB` ) : '...',
          });
        });
      // orgAPI.getProcessingGPInfo(stroot('hi7ohd'))
      //   .then((res) => {
      //     setProcessingData(res.body);
      //   });

      if (meta.organisation.externalBilling) return;

      subscriptionAPI.getCurrentPlan(stroot('phahc4'))
        .then((res) => {
          setCurrentPlan(res.body);
        })
        .catch(() => {
          // Expecting this to catch during processing preview or when
          // external billing is true, for now
        });
    }
  }, []);

  useEffect(() => {
    let localOldPlan = localStorage.getItem('localOldPlan');
    let cancelPlan = localStorage.getItem('cancelPlan');
    if ((localOldPlan) &&
        (meta.organisation.plan) &&
        ((localOldPlan !== meta.organisation.plan) || (cancelPlan === 'yes') || (localOldPlan === meta.organisation.plan && currentPlan?.status === 'canceled'))
      ) {
      setOldPlan(localOldPlan);
      if (cancelPlan === 'yes') setCancellingPlan(cancelPlan);
      setPlanChangedModal(true);
    }
  }, [meta.organisation.plan || currentPlan?.status]);

  if ((!currentPlan && meta.organisation.plan !== 'free' && !meta.organisation.externalBilling)) return (
    <div css={css`padding: 2em`}>
      <LoadingSpinner color="#2287FA" size="1.5em" />
    </div>
  );

  const plansInfo = [
    {
      option: 'free',
      title: 'Free',
      hasPeriod: false,
      icon: () => <FreePlanSVG />,
      manage: {
        adminSeats: 'upgrade',
        workspaces: 'upgrade',
        processing: 'upgrade',
        storage: 'add',
      }
    },
    {
      option: 'plus',
      title: 'Plus',
      hasPeriod: true,
      icon: () => <PlusPlanSVG />,
      manage: {
        adminSeats: 'upgrade',
        workspaces: 'upgrade',
        processing: 'upgrade',
        storage: 'add',
      }
    },
    {
      option: 'growth',
      title: 'Growth',
      hasPeriod: true,
      icon: () => <GrowthPlanSVG />,
      manage: {
        adminSeats: 'add',
        workspaces: 'add',
        processing: null,
        storage: 'add',
      }
    },
    {
      option: 'pro',
      title: 'Pro',
      hasPeriod: false,
      icon: () => <GrowthPlanSVG />,
      manage: {
        adminSeats: null,
        workspaces: null,
        processing: null,
        storage: null,
      }
    },
    {
      option: 'enterprise',
      title: 'Ultimate',
      hasPeriod: false,
      icon: () => <EnterprisePlanSVG />,
      manage: {
        adminSeats: 'add',
        workspaces: 'add',
        processing: null,
        storage: 'add',
      }
    },
  ];

  const currentPlanInfo = plansInfo.find((item) => item.option === meta.organisation.plan);

  return (
    <div>
      <h2 css={css`margin: 1em`}>Plan & usage</h2>
      <div css={pageGraySection}>
        <p>Manage your plan and usage.</p>
      </div>

      <div css={css`padding: 0 1em`}>
        <div css={css`
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1em;
          `}>
          <h3>Subscription Plan</h3>
          {!meta.organisation.externalBilling && (
            <Link
              to='/billing/options'
              css={(theme) => css`
                padding: 0.5em;
                background-color: ${theme.lightOcean};
                border-radius: 5px;
                span {
                  color: ${theme.deepOcean};
                  text-decoration: underline
                }
              `}
            >
              <span>Change plan</span>
            </Link>
          )}
        </div>

        {!meta.organisation.externalBilling && (
          <div css={css`margin-bottom: 1em;`}>
            {meta.organisation.plan !== 'free' && currentPlan.status !== 'canceled' && (
              <span>
                {`Subscription renewal date: ${format(currentPlan.currentPeriodEnd * 1000, 'dd MMM yyyy')}`}
              </span>
            )}
            {currentPlan && currentPlan.status === 'canceled' && (
              <span>
                {`Your subscription has been cancelled and is active until ${format(currentPlan.currentPeriodEnd * 1000, 'dd MMM yyyy')}`}
              </span>
            )}
          </div>
        )}

        <div css={(theme) => css`
          display: flex;
          flex-direction: row;
          border: solid 1px ${theme.mono30};
          border-radius: 8px;
          padding: 0.5em 1em;
          width: 35em;
          @media (max-width: ${breakpoints.xs}) {
            width: calc(100% - 2em);
          }
        `}>
          <div css={css`
            vertical-align: middle;
            margin: 1em 2em 1em 0; // 2em 2em 2em 0;
            @media (max-width: ${breakpoints.xs}) {
              display: none;
              margin: 0;
            }
            svg { font-size: 7em; }
          `}
          >
            {currentPlanInfo?.icon()}
          </div>
          <table css={css`
            width: 100%;
          `}
          >
            <tr>
              <td>
                <span css={css`font-weight: bold`}>{currentPlanInfo?.title} Plan</span>
              </td>
              {!meta.organisation.externalBilling && (
                <td>{currentPlanInfo?.hasPeriod ? (currentPlan.basePlan.plan.interval === 'month' ? 'Monthly' : 'Annually') : ''}</td>
              )}
            </tr>
            <tr>
              <td>Admin Seats</td>
              <td>{seats.unlimitedSeats ? 'Unlimited' : `${seats.baseSeatQuantity} Included`}</td>
            </tr>
            <tr>
              <td>Guest Seats</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <td>Storage</td>
              <td>{storageData ? storageData.storageGb : loadingIcon} Data Included</td>
            </tr>
          </table>
        </div>

        <h3 css={css`margin-top: 2em`}>Current Usage</h3>

        <div css={(theme) => css`
          margin: 1em 0 2em 0;
          border: solid 1px ${theme.mono30};
          border-radius: 8px;
          width: 37em;
          @media (max-width: ${breakpoints.xs}) {
            width: 100%
          }
        `}>
          <table css={(theme) => css`
            width: 100%;
            td { padding: 0.5em 1em };
            thead tr {
              border-bottom: solid 1px ${theme.mono30};
              td { font-weight: bold }
            };
          `}
          >
            <thead>
              <tr>
                <td>Product</td>
                <td>Usage</td>
                <td>Manage</td>
              </tr>
            </thead>
            <tr>
              <td>Admin Seats</td>
              {seats.unlimitedSeats ? (
                <td>{`${seats.userCount} seats used`}</td>
              ) : (
                <td>{`${seats.userCount} / ${totalSeats(seats)} paid seats used`}</td>
              )}
              {currentPlanInfo?.manage.adminSeats === 'upgrade' && (
                <td>
                  <button
                    type="button"
                    onClick={() => history.push('/billing/options')}
                    css={manageButtonStyle}
                  >
                    <span>Upgrade</span>
                  </button>
                </td>
              )}
              {currentPlanInfo?.manage.adminSeats === 'add' && !meta.organisation.externalBilling && (
                <td>
                  <ManageSeatsButton modalOpen={seatsModal} setModalOpen={setSeatsModal} label="+ Add seats" />
                </td>
              )}
            </tr>
            <tr>
              <td>Guest Seats</td>
              <td>{`${seats.guestCount} seats used`}</td>
            </tr>
            <tr>
              <td>Storage</td>
              <td>
                {storageData ? storageData?.mediaGb : loadingIcon}
                &nbsp;/&nbsp;
                {storageData ? storageData.storageGb : loadingIcon}
                &nbsp;
                used
              </td>
              {currentPlanInfo?.manage.storage && (
                <td>
                  <a
                    href="mailto:support@birdi.io"
                    target="_blank"
                    css={manageButtonStyle}
                    rel="noreferrer"
                  >
                    <span>Contact us</span>
                  </a>
                </td>
              )}
            </tr>
          </table>
        </div>

      </div>

      {planChangedModal && oldPlan && (
        <PlanChangedModal
          open={planChangedModal}
          close={() => setPlanChangedModal(false)}
          oldPlan={oldPlan}
          newPlan={meta.organisation.plan}
          cancelPlan={!!(cancellingPlan === 'yes')}
        />
      )}
    </div>
  );
};
