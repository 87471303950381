import * as Dialog from '@radix-ui/react-dialog';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import CloseSVG from '@birdi/icons/close.svg';
import { css } from '@emotion/react';
import { format } from 'date-fns/fp';
import type { InvoiceItem, UpcomingInvoice } from './invoices';
import { numberToCurrencyStr } from '../utils';

interface UpcomingInvoiceModalProps {
  invoice: UpcomingInvoice;
  open: boolean;
  onChange: (value: boolean) => void;
}

const fmt = format('MMM dd, yyyy');
const formatLinePeriod = (period: InvoiceItem['period']) => `${fmt(period.start * 1000)} - ${fmt(period.end * 1000)}`;

export const UpcomingInvoiceModal = ({ invoice, open, onChange }: UpcomingInvoiceModalProps) => {
  // TODO: weirdness with prorated seat stuff - how to deal with?
  const coupon = invoice.discount?.coupon;
  const couponValue = coupon
    ? invoice.total_discount_amounts.find((x) => x.discount === invoice.discount!.id)?.amount || 0
    : 0;

  return (
    <Dialog.Root open={open} onOpenChange={onChange}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <Dialog.Content css={contentCSS}>
            <Dialog.Close css={closeButtonCSS}><CloseSVG /></Dialog.Close>
            <Dialog.Title asChild>
              <h1 css={css`margin-bottom: 0;`}>
                Upcoming Invoice
              </h1>
            </Dialog.Title>
            <div css={css`margin-bottom: 2rem;`}>
              {fmt(invoice.period_end * 1000)}
            </div>

            <div css={css`display: flex; flex-direction: column; gap: 0.5rem;`}>
              {invoice.lines.data.map((i) => (
                <div
                  key={i.id}
                  css={(theme) => css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  &:not(:first-child) {
                    border-top: solid 1px ${theme.hailstone};
                    padding-top: 0.5rem;
                  }
                `}
                >
                  <div
                    css={css`
                    display: flex;
                    flex-direction: column;
                    flex-shrink: 1;
                  `}
                  >
                    <span css={css`font-weight: 700;`}>{i.description}</span>
                    <span>{formatLinePeriod(i.period)}</span>
                  </div>

                  <span css={css`flex-shrink: 0;`}>{numberToCurrencyStr(i.amount, i.currency)}</span>
                </div>
              ))}
            </div>

            <div
              css={(theme) => css`
                display: flex;
                flex-direction: column;
                margin-top: 2rem;
                gap: 0.5rem;
                & > :not(:first-child) {
                  border-top: solid 1px ${theme.hailstone};
                  padding-top: 0.5rem;
                }
              `}
            >
              <div css={css`display: flex; align-items: center; justify-content: space-between;`}>
                Subtotal:
                <span>{numberToCurrencyStr(invoice.subtotal, invoice.currency)}</span>
              </div>
              {/* Handling multiple discounts? - probably needs more fetches */}
              {coupon && (
                <div css={css`display: flex; align-items: center; justify-content: space-between;`}>
                  {coupon.name}
                  {' '}
                  {coupon.percent_off ? `(${coupon.percent_off}% off)` : ''}
                  <span>
                    -
                    {numberToCurrencyStr(couponValue, invoice.currency)}
                  </span>
                </div>
              )}
              <div>
                {invoice.total_tax_amounts && typeof invoice.total_tax_amounts[0].amount === 'number' && (
                  <span>
                    GST:
                    {' '}
                    {numberToCurrencyStr(invoice.total_tax_amounts[0].amount, invoice.currency)}
                  </span>
                )}
              </div>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-weight: 700;
                `}
              >
                Total (incl. GST):
                <span>{numberToCurrencyStr(invoice.total, invoice.currency)}</span>
              </div>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
