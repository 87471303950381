import { next } from '@birdi/theme';
import React from 'react';

export const Root = React.forwardRef<HTMLDListElement, JSX.IntrinsicElements['dl']>((props, ref) => (
  <dl css={next.dl.root} ref={ref} {...props} />
));

export const Item = React.forwardRef<HTMLDivElement, JSX.IntrinsicElements['div']>((props, ref) => (
  <div css={next.dl.ddiv} ref={ref} {...props} />
));

export const Label = React.forwardRef<HTMLElement, JSX.IntrinsicElements['dt']>((props, ref) => (
  <dt css={next.dl.dt} ref={ref} {...props} />
));


export const Value = React.forwardRef<HTMLElement, JSX.IntrinsicElements['dd']>((props, ref) => (
  <dd css={next.dl.dd} ref={ref} {...props} />
));
