import React, { useState, useEffect } from 'react';
import { stroot, auth, user } from '@birdi/js-sdk';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';
import { ContextMenuHOC } from '@birdi/context-menu';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { useMetaSelector } from '../meta/reducer';
import { AddWorkspaceModal } from './add-workspace-modal';

// eslint-disable-next-line
export const UserWorkspaces: React.FunctionComponent = ContextMenuHOC(({
  open, wrapperRef, // Context menu props
}) => {
  const meta = useMetaSelector();
  const [userScopes, setScopes] = useState([]);
  const [showAddWorkspaceModal, setShowAddWorkspaceModal] = useState(false);

  useEffect(() => {
    user.getScopes(stroot('ior5ah'))
      .then((data) => {
        setScopes(data.body.items);
      });
  }, []);

  const addNewWorkspace = () => {
    setShowAddWorkspaceModal(true);
  };

  return (
    <div css={css(overlayMenuContainerCSS)}>
      <AddWorkspaceModal
        open={showAddWorkspaceModal}
        setOpen={setShowAddWorkspaceModal}
      />
      <CSSTransition
        in={open}
        timeout={150}
        classNames="drop-menu"
        unmountOnExit
      >
        <div
          css={(theme: BirdiTheme) => css`
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 280px;
            top: 2.25em;
            left: 0;
            color: ${theme.midnight};
            background: ${theme.mono0};
            box-shadow: 0 0 10px ${theme.mono40};
            border-radius: 3px;
            overflow: hidden;
            padding: 0.5em;
            max-height: calc(100vh - 10em);;
            overflow-y: scroll;
          `}
          ref={wrapperRef}
        >
          <h3
            id="my-workspaces"
            css={css`padding: 0.5em 0.5em 0 0.5em; margin: 0;`}
          >
            My workspaces
          </h3>
          {userScopes.map((scope) => (
            <div key={scope.id}>
              <hr css={css`margin: 0.5em 0`} />
              <button
                type="button"
                className="item"
                css={(theme: BirdiTheme) => css`
                  width: 100%;
                  padding: 0.5em;
                  white-space: nowrap;
                  text-align: left;
                  color: ${scope.active ? theme.deepOcean : theme.midnight};
                  background: ${scope.active ? theme.mono20 : theme.mono0};
                  &:hover {
                    background: ${theme.mono20};
                  }
                `}
                onClick={() => {
                  auth.switchContext(stroot('aigee1'), {
                    scopeId: scope.id,
                  }).then(() => window.location = '/');
                }}
              >
                <span>{scope.organisationName}</span>
                <p css={(theme: BirdiTheme) => css`
                  margin: 0.25em 0 0 0;
                  font-size: 0.8em;
                  color: ${theme.mono50};
                `}
                >
                  {scope.role}
                </p>
              </button>
            </div>
          ))}
          {meta.permissions.includes('org:add-new-workspace') && (meta.orgFeatureFlags.includes(FeatureFlagNames.MultipleWorkspaces)) && (
            <>
              <hr css={css`margin: 0.5em 0`} />
              <button
                type="button"
                css={(theme) => css`
                  ${buttonBlue(theme, { baseSize: 'sm' })};
                  background-color: ${theme.mono0};
                  border: solid 1px ${theme.mono98};
                  color: ${theme.mono98};
                  width: 100%;
                  margin: 0.5em 0;
                  padding-top: 0.5em ;
                  padding-bottom: 0.5em ;
                  &:active {
                    color: ${theme.mono98};
                  }
                `}
                onClick={addNewWorkspace}
              >
                + New Workspace
              </button>
            </>
          )}
        </div>
      </CSSTransition>
    </div>
  );
});
