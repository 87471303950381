import { css } from '@emotion/react';
import { OptionTypeBase, StylesConfig } from 'react-select';
import { themeLight, BirdiTheme } from './theme';

export const blockLabel = css`
  display: block;
  margin-bottom: 1rem;
`;

export const formHeader = css`
  margin: 1.25em 0 0.25em 0;
  font-size: 1rem;
  font-weight: 600;
`;

// TODO: box-sizing: border-box
export const textInput = (theme: BirdiTheme) => css`
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid ${theme.mono40};
  margin-bottom: 1rem;
  &:disabled {
    background: ${theme.mono20};
  };
  &:focus {
    outline: 2px solid ${theme.deepOcean};
  }
`;

export const textArea = (theme: BirdiTheme) => css`
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid ${theme.mono40};
  margin-bottom: 1rem;
  min-height: 5rem;
`;

export const selectInput = (theme: BirdiTheme) => css`
select {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}`;

export const errorStyle = css`
  display: block;
  margin-bottom: 1em;
  color: red;
  font-weight: 300;
`;

export const errorMessage = css`
  display: block;
  margin: -0.8rem 0 1rem 0;
  font-weight: 300;
  color: red;
`;

export const datePickerStyle = (theme: BirdiTheme) => css`
  input& {
    width: 100%;
    height: 3.5em;
    padding: 0 1em;
    border: solid 1px ${theme.mono30};
    color: ${theme.mono98};
    background: none;
    border-radius: 5px;
    text-align: left;
    font-size: 1rem;
    margin-top: 0.5em;
  }
`;

export const nextButtonHolder = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 0.5rem;
`;

export const bigCheckboxStyle = (theme: BirdiTheme) => css`
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  accent-color: ${theme.deepOcean};
`;

// This brings the react-select component's padding
// inline with textInput
// assign to the Select.style prop
export const reactSelect: StylesConfig<OptionTypeBase> = ({
  control: (base) => ({
    ...base,
    borderRadius: '2px',
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  input: (base) => ({
    ...base,
    padding: '0.75rem',
    margin: 0,
  }),
});

export const CustomReactSelectStyles: StylesConfig<OptionTypeBase, false> = {
  control: (provided) => ({
    ...provided,
    height: '100%',
    width: '100%',
    borderRadius: 0,
    borderColor: themeLight.darkGrey,
    backgroundColor: 'transparent',
    paddingLeft: '0.5rem',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0.5625rem',
    fontSize: '0.875rem',
    lineHeight: '1rem',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: themeLight.darkGrey,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: themeLight.darkGrey,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: themeLight.darkGrey,
  }),
};
