import React, { useState } from 'react';
import { css } from '@emotion/react';
import { buttonBlue, BirdiTheme } from '@birdi/theme';
import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import { useMetaSelector } from '../meta/reducer';

export const changePlanButtonStyle = (theme: BirdiTheme) => css`
  ${buttonBlue(theme)};
  width: 12em;
  margin: 1em;
  height: 2.5em;
  border-radius: 0.5em;
`;

// (theme: BirdiTheme, label: string) => ...
// width: ${(label === 'Start a 14-day free trial') ? '12em' : '7em'};

export const Checkout: React.FC<{ priceId: string, label: string, selectedPlan: string, differentInterval: boolean, planIsCanceled: boolean }> =
  ({ priceId, label, selectedPlan, differentInterval, planIsCanceled }) => {
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const meta = useMetaSelector();

  // by changing just plan interval, organisation users' role won't change
  const downgradeAlertMessage = (meta.organisation.plan === 'growth' && selectedPlan === 'plus')
    ? 'Are you sure you want to downgrade your subscription? By downgrading your subscription, all organisation users except you will become guest users.'
    : 'Are you sure you want to downgrade your subscription?';
  const upgradeAlertMessage = 'Are you sure you want to update your subscription?';

  async function checkout() {
    if (label === 'Downgrade') {
      const confirmed = window.confirm(downgradeAlertMessage);
      if (!confirmed) return;
    }
    if (meta.organisation.plan !== 'free' && label === 'Upgrade') {
      const confirmed = window.confirm(upgradeAlertMessage);
      if (!confirmed) return;
    }
    setCheckoutLoading(true);
    if (meta.organisation.plan === 'free' || planIsCanceled) {
      const res = await subscriptionAPI.checkoutSession(stroot('oh4ahn'), {
        priceId,
      });
      window.open(res.body.url, '_self');
    } else {
      localStorage.setItem('localOldPlan', meta.organisation.plan);
      const res = await subscriptionAPI.updateSubscription(stroot('ohc8ei'), {
        priceId,
      });
      window.location.assign(`${location.origin}/admin-plan`)
    }
  }

  const downgradeFromGrowthToPlus = (selectedPlan === 'plus' && label === 'Downgrade');

  return (
    <button
      disabled={checkoutLoading || differentInterval || downgradeFromGrowthToPlus}
      onClick={checkout}
      css={(theme) => changePlanButtonStyle(theme)}
      type="button"
    >
      {label}
    </button>
  );
};
