import React from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme';
import { CreateDropdown } from './create-dropdown';

type MapDashboardHeaderProps = {
  titleComponent: React.ReactNode;
};
export function MapDashboardHeader({titleComponent}: MapDashboardHeaderProps) {
  return (
    <div css={(theme) => css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;
      height: 2.5rem;
      @media (min-width: ${breakpoints.xs}) {
        border-bottom: solid 2px ${theme.mono20};
        padding-bottom: 1rem;
      }
    `}>
      <div css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}>
        {titleComponent}
      </div>
      <div css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      `}>
        <CreateDropdown />
      </div>
    </div>
  );
}
