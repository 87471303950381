import { stroot } from '@birdi/js-sdk'
import React from 'react';
import AsyncSelect from 'react-select/async';
import { organisation as organisationAPI } from '@birdi/js-sdk/src/public';
import { reactSelect } from '@birdi/theme/form';

export function loadOptions() {
  return organisationAPI.getRoles(stroot('ohw4ae'))
    .then((response) =>
      response.body.items.map((role) => ({ label: role.name, value: role.id })));
}

export default ({ onChange }) => (
  <AsyncSelect
    defaultOptions
    cacheOptions
    isClearable
    onChange={(option) => onChange(option)}
    onClear={() => onChange(null)}
    loadOptions={loadOptions}
    placeholder="Filter by role"
    styles={{
      ...reactSelect,
      input: (base) => ({
        ...base,
        padding: '0.75rem',
        margin: 0,
        minWidth: '15em',
      }),
    }}
  />
);
