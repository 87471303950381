/* global fetch */

import { config } from '../config';
import {
  APIResponse, fetchAPI, headers, SpanTag, stleaf,
} from '../helpers';

export interface Map {
id: string;
  name: string;
  createdBy: string;
  createdAt: string;
  position?: any;
}

export interface GetManyMapsOptions {
  start?: number;
  limit?: number;
}

type GetMapsResponse = Promise<APIResponse<Map[]>>;
export const getMaps = (st: SpanTag, opts?: GetManyMapsOptions): GetMapsResponse => {
  const url = new URL(`${config.API_URL}/v1/maps/`);
  if (opts) url.search = new URLSearchParams(opts as any).toString();
  return fetchAPI(stleaf(st, 'getmps'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  }) as GetMapsResponse;
};

type PostMapResponse = Promise<APIResponse<{ id: string }>>;
export const postMap = (st: SpanTag): PostMapResponse => {
  const url = `${config.API_URL}/v1/maps/`;
  return fetchAPI(stleaf(st, 'pstmap'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  }) as PostMapResponse;
};

/**
* BEWARE, e2e these events need to broadcasted to all ws2 connected clients
*/
type UpdateMapResponse = Promise<APIResponse<Map>>;
export const updateMap = (st: SpanTag, id: string, body: Partial<Map>): UpdateMapResponse => {
  const url = `${config.API_URL}/v1/maps/${id}`;
  return fetchAPI(stleaf(st, 'updmap'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  }) as UpdateMapResponse;
};

type DeleteMapResponse = Promise<APIResponse<void>>;
export const deleteMap = (st: SpanTag, id: string): DeleteMapResponse => {
  const url = `${config.API_URL}/v1/maps/${id}`;
  return fetchAPI(stleaf(st, 'dltemp'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  }) as DeleteMapResponse;
};

type GetLayerMediaInfoResponse = Promise<APIResponse<{ thumbnailUrl?: string; fullImageUrl?: string; }>>;
export const getLayerMediaInfo = (st: SpanTag, mapId: string, layerId: string): GetLayerMediaInfoResponse => {
  const url = `${config.API_URL}/v1/maps/${mapId}/layers/${layerId}/media-info`;
  return fetchAPI(stleaf(st, 'iosh9b'), url, {
    method: 'GET',
    credentials: 'include',
    headers
  })
}
