import React from 'react';
import Select from 'react-select';
import { reactSelectLight } from '@birdi/theme/blocks';
import { hasPermission } from '@birdi/utils/src/helpers';

const associations = [
  { value: 'mine', label: 'My equipment' },
];

const orgAssociations = [
  { value: 'mine', label: 'My equipment' },
  { value: 'organisation', label: 'Organisation equipment' },
];

export const EquipmentAssociationSelect = ({
  onChange, isLoading, selectedAssociation, permissions,
}) => (
  <Select
    isSearchable={false}
    onChange={(opt) => onChange({ association: opt.value })}
    value={hasPermission(permissions, 'org:manage-missions') ? orgAssociations.find((opt) => opt.value === selectedAssociation)
      : associations.find((opt) => opt.value === selectedAssociation)}
    options={hasPermission(permissions, 'org:manage-missions') ? orgAssociations : associations}
    isLoading={isLoading}
    theme={reactSelectLight}
    styles={{
      control: (provided) => ({
        ...provided,
        height: '100%',
        width: '15em',
      }),
    }}
  />
);
