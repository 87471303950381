import { css } from '@emotion/react';
import { cardList } from '@birdi/theme/blocks';
import { type maps } from '@birdi/js-sdk';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import { Cross1Icon } from '@radix-ui/react-icons';

export interface MissionListProps {
  maps: maps.Map[];
  deleteMission?: (id: string) => void;
  isDashboard?: boolean;
}

export const NewMapList = ({
  maps,
  deleteMission,
  isDashboard = false,
}: MissionListProps) => {
  return (
    <div css={cardList}>
      {maps.map((map, index) => (<MapCard key={index} map={map}/>))}
    </div>
  );

};

type MapCardProps = {
  map: maps.Map;
};
const MapCard: React.FC<MapCardProps> = ({ map }) => {
  return (
    <div css={map2CardStyle} onClick={() => window.location.href = `/map/next/${map.id}`}>
      <div
        className="card-cover"
        css={css`
          position: relative;
          height: 12.5rem;
          background-color: #f5f5f5;
          background-size: cover;
          background-position: center;
          text-align: center;
          border-radius: 2px;
          background-image: url('https://birdi-public.s3-ap-southeast-2.amazonaws.com/street-background.png');
        `}
      >
      </div>
        <div
          className="card-body"
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 1em;
            padding-bottom: 0.5em;
            height: 7.5em;
          `}
        >
          <h3
            data-testid="name"
            css={css`
              text-transform: capitalize;
              margin: 0;
              height: 2.5em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: calc(100% - 2em);
            `}
          >
            {map.name || 'Untitled Map'}
          </h3>
          <div
            css={css`
                width: 100%;
                display: flex;
                justify-content: flex-end;
              `}>
            <div
              css={css`
                  width: 100px;
                  background-color: #EFFBF5;
                  border-radius: 8px;
                  padding: 3px;
                `}>
              <p
                css={css`
                    margin: 0;
                  `}
                >Birdi 2.0 map</p>
            </div>
          </div>

        </div>
      </div>
  );
};

const map2CardStyle = (theme: BirdiTheme) => css`
    display: inline-block;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 3px 3px 10px ${theme.mono30};
    border: solid 1px ${theme.mono20};
    transition: 0.15s shadow, 0.15s transform;
    cursor: pointer;
    width: 100%;
    margin: 0 0.1rem 1rem 0;
    @media (min-width: ${breakpoints.xs}) {
      width: calc((100% / 2) - 1rem);
      margin: 0 1rem 1rem 0;
    }
    @media (min-width: ${breakpoints.s}) {
      width: calc((100% / 3) - 1rem);
    }
    &:focus, & > span:focus {
      outline: 0;
    }
    &:focus > span  {
      outline: -webkit-focus-ring-color auto 5px;
    }
`;
