import { next } from '@birdi/theme';
import { css, Interpolation, Theme } from "@emotion/react";
import React from 'react';
import { Spinner } from './spinner';

export type ButtonVariant = 'solid' | 'outline';

export interface BaseButtonProps {
  loading?: boolean;
  variant?: ButtonVariant;
}

export type ButtonProps = BaseButtonProps & JSX.IntrinsicElements['button'];

const variants: Record<ButtonVariant, Interpolation<Theme>> = {
  solid: next.buttons.primary,
  outline: next.buttons.secondary,
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ loading, children, variant = 'solid', ...props }, ref) => (
  <button css={variants[variant]} ref={ref} {...props}>
    {!loading ? children : (
      <>
        <span css={css`display: contents; visibility: hidden;`}>{children}</span>
        <Spinner color="currentColor" css={css`position: absolute;`}/>
      </>
    )}
  </button>
));

// TODO: probably have a common BaseButton component
export type AnchorButtonProps = BaseButtonProps & JSX.IntrinsicElements['a'];

export const AnchorButton = React.forwardRef<HTMLAnchorElement, AnchorButtonProps>(({ loading, children, variant = 'solid', ...props }, ref) => (
  <a css={variants[variant]} ref={ref} {...props}>
    {!loading ? children : (
      <>
        <span css={css`display: contents; visibility: hidden;`}>{children}</span>
        <Spinner color="currentColor" css={css`position: absolute;`}/>
      </>
    )}
  </a>
));

export const IconButton = React.forwardRef<HTMLButtonElement, JSX.IntrinsicElements['button']>((props, ref) => (
  <button css={next.buttons.icon} ref={ref} {...props} />
))
