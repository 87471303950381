import React, { useEffect, useState } from 'react';
import { stroot, organisationUsers } from '@birdi/js-sdk';
import {  withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import { useMetaSelector } from '../meta/reducer';
import { pageTitleHolder, pageTitle, pageGraySection } from '@birdi/theme/blocks';
import { breakpoints } from '@birdi/theme/variables';
import { PermissionsComponent } from '../admin/permissions-component';
import RolesComponent from '../admin/roles-component';

const Permissions = withRouter(({ match }) => {
  const meta = useMetaSelector();
  // if (meta.scopeId === match.params.scopeId) => myself

  const [details, setDetails] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    // if (meta.scopeId === match.params.scopeId) {
    //   // myself
    //   organisationUsers.getMyDetails(stroot('if9eey'))
    //     .then((res) => setDetails(res.body));
    // }

    organisationUsers.getUserDetails(stroot('as7oo7'), match.params.scopeId)
      .then((res) => setDetails(res.body));
  }, [meta.loaded]);

  if (!details) return <div />;

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>{details.fname} {details.lname}</h2>
      </div>
      <div css={pageGraySection}>
        <p>Manage users role and permissions.</p>
      </div>

      <div css={css`padding: 1em`}>
        <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          width: calc(100% - 3em);
          gap: 1em;
          @media (max-width: ${breakpoints.xs}) {
            flex-direction: column;
            width: 100%;
          };
        `}
        >
          <div css={css`display: flex; flex: 1; flex-direction: column; width: 100%`}>
            <h3 css={css`margin-top: 0`}>Default account roles</h3>
            <RolesComponent
              details={details}
              setSelectedRole={setSelectedRole}
              isMyself={meta.scopeId === match.params.scopeId}
            />
          </div>
          <div css={css`display: flex; flex: 1; flex-direction: column; width: 100%; opacity: 0.4`}>
            <h3 css={css`margin-top: 0`}>Role permissions</h3>
            <PermissionsComponent role={selectedRole} />
          </div>
        </div>
      </div>
    </div>
  )
});

export default Permissions;
