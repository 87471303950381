import React from 'react';
import { css } from '@emotion/react';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import TrashSVG from '@birdi/icons/trash.svg';
import { hasMissionPermission } from '@birdi/utils/src/helpers';
import { BirdiTheme } from '@birdi/theme';

interface MissionContextMenuProps {
  mission: any;
  deleteMission?: (id: string) => void;
}

export function MissionContextMenu({ mission, deleteMission }: MissionContextMenuProps) {
  const canDelete = hasMissionPermission(mission, 'delete');
  if (!canDelete || deleteMission === null) return false;
  return (
    <>
      <ContextMenu>
        {() => (
          <div css={(theme: BirdiTheme) => css(contextStyles(theme))}>
            {(canDelete && deleteMission) && (
            <button type="button" onClick={() => deleteMission(mission.id)}>
              <TrashSVG />
              <span>Delete</span>
            </button>
            )}
          </div>
        )}
      </ContextMenu>
    </>
  );
}
