/* globals window */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { buttonOutlineBlack, breakpoints } from '@birdi/theme';
import {
  pageTitleHolder, pageTitle, pageGraySection,
  input, separator2,
} from '@birdi/theme/blocks';
import { UserTable } from './UserTable';
import * as userActions from './reducer';
import { InviteUserModal } from './invite-user-modal';
import { SeatsBadge } from './seats-badge';
import { useMetaSelector } from '../meta/reducer';
import { INTERCOM_TOUR_CODES } from '../common/consts';
import { LoadingSpinner } from '@birdi/loading';
import { Pagination } from '@birdi/pagination';
import { stroot, organisationUsers } from '@birdi/js-sdk/src/public';
import RoleSelect from './role-select';
import SearchSVG from '@birdi/icons/search.svg';

const LOAD_AMOUNT = 20;

interface Filters {
  search: string;
  roleId: string,
}

export const Users: React.FC = () => {
  // TODO: Self-contained modal
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showManageSeatsModal, setShowManageSeatsModal] = useState(false);
  const meta = useMetaSelector();
  const users = useSelector((state) => state.users);

  const [usersList, setUsersList] = useState([]);
  const [filters, setFilters] = useState<Filters>({
    search: '', roleId: null,
  });
  const [start, setStart] = useState(1);
  const [count, setCount] = useState(0);

  const history = useHistory();

  const searchUsers = (filters: Filters, start: number = 1) => {
    const params = {
      start,
      limit: LOAD_AMOUNT,
      ...(filters.search && {search: filters.search }),
      ...(filters.roleId && { roleId: filters.roleId }),
    };
    organisationUsers.getUsers(stroot('iteih4'), params)
      .then((res) => {
        setUsersList(res.body.items);
        setCount(res.body.itemCount);
      });
  }

  useEffect(() => {
    searchUsers(filters);

    function openModalOnSearch() {
      const modalParam = new URLSearchParams(window.location.search).get('modal');
      if (modalParam === 'invite') {
        setShowManageSeatsModal(false);
        setShowInvitationModal(true);
        // Removes query
        window.history.replaceState('users', 'Birdi', '/admin-users');
      }
      if (modalParam === 'seats') {
        setShowInvitationModal(false);
        setShowManageSeatsModal(true);
        window.history.replaceState('users', 'Birdi', '/admin-users');
      }
    }
    openModalOnSearch();
    const deregister = history.listen(openModalOnSearch);
    return () => deregister();
  }, []);

  const updateFilters = (updatedFilter: Partial<Filters>) => {
    setFilters({ ...filters, ...updatedFilter });
    setStart(1);
    searchUsers({ ...filters, ...updatedFilter });
  }

  const updatePage = (page: number) => {
    setStart(page);
    searchUsers(filters, page);
  };

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Users</h2>
      </div>
      <div css={(theme) => css`
        ${pageGraySection(theme)};
        justify-content: space-between;
        @media (max-width: ${breakpoints.m}) {
          padding-bottom: 1em
        }
      `}>
        <div css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          @media (max-width: ${breakpoints.m}) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            p { margin-bottom: 0 }
          }
          `}
        >
          <p css={css`margin-right: 0.5em`}>
            Manage, update and invite team members to join your organization.
          </p>
          <div css={css`margin-top: 1em`}>
            <SeatsBadge modalOpen={showManageSeatsModal} setModalOpen={setShowManageSeatsModal} />
          </div>
        </div>
        <Link
          // to={inviteDisabled ? '#' : '/admin-users?modal=invite'}
          to="/admin-users?modal=invite"
          css={(theme) => css`
            ${buttonOutlineBlack(theme)};
            background-color: ${theme.mono0};
            border: 1px solid ${theme.mono40};
            border-radius: 4px;
            height: 2em;
            span {
              color: ${theme.mono50};
            }
          `}
        >
          <span id="invite-a-user">+ Invite a user</span>
        </Link>
      </div>
      {meta.permissions.includes('org:manage-users') && (
        <div>
          <InviteUserModal
            open={showInvitationModal}
            setOpen={setShowInvitationModal}
          />
        </div>
      )}

      <div css={(theme) => css`${separator2(theme)}`}>
        <form
          role="search"
          onSubmit={(event) => { event.preventDefault() }}
          css={css`
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            & > *:not(last-child) {
              margin-right: 0.5em;
            };
            gap: 0.5em;
            @media (max-width: ${breakpoints.xs}) {
              flex-direction: column;
            };
          `}
        >
          <div
            css={css`
              position: relative;
              display: inline-block;
            `}
          >
            <SearchSVG
              css={(theme) => css`
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                pointer-events: none;
                height: 1em;
                width: 1em;
                color: ${theme.mono40}
              `}
            />
            <input
              id="name"
              type="text"
              value={filters.search}
              placeholder="Search by name"
              onChange={(event) => updateFilters({ search: event.target.value })}
              css={(theme) => css`
                ${input(theme)};
                border: 1px solid #ccc !important;
                min-width: 20em;
                padding-left: 2em;
                &::placeholder {
                  font-size: 1em;
                }
              `}
            />
          </div>
          <RoleSelect
            onChange={(role) => updateFilters({ roleId: role ? role.value : null })}
          />
        </form>
      </div>

      {usersList.length === 0 ? (
        <div css={css`padding: 2em`}>
          No users found ...
        </div>
      ) : (
        <section css={css`padding-bottom: 2em;`}>
          <UserTable
            users={usersList}
            id={meta.id}
            permissions={meta.permissions}
          />
          <div css={css`padding: 1em 0`}>
            <Pagination
              onChange={(currentPage) => updatePage(currentPage)}
              itemCount={count}
              itemsPerPage={LOAD_AMOUNT}
              currentPage={start}
            />
          </div>
        </section>
      )}
    </div>
  );
};
