import React, { useEffect, useState } from 'react';
import { stroot, organisation as organisationAPI } from '@birdi/js-sdk';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { itemsTable } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { Toggle } from '@birdi/toggle';
import BirdiLogoWhiteSVG from '@birdi/icons/birdi-logo-white.svg';
import BirdiLogoNavySVG from '@birdi/icons/birdi-logo-navy.svg';
import LoaderSVG from '@birdi/icons/loader.svg';
import HandsSVG from '@birdi/icons/thank-you.svg';
import { uploadOne, fileToUppyOpts } from '@birdi/uppy-s3';
import { textInput } from '@birdi/theme/form';
import * as Toast from '@radix-ui/react-toast';
import * as styles from './styles';
import { useMetaSelector, updateOrganisation } from '../meta/reducer';
import { pageTitleHolder, pageTitle, pageGraySection } from '@birdi/theme/blocks';

interface Fields {
  name: string;
  logo: string;
  emailHeader: string;
  displayBranding: boolean;
  displayLogo: boolean;
  displayEmailHeader: boolean;
}

const boxStyle = (theme: BirdiTheme) => css`
  width: 70%;
  margin: 1em;
  padding: 1em;
  border: solid 1px ${theme.mono30};
  border-radius: 5px;
  @media (max-width: ${breakpoints.xs}) {
    width: calc(100% - 5em);
  };
`;
const firstRowStyle = (theme: BirdiTheme) => css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
`;
const imageRowStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
`;
const uploadButtonStyle = (theme: BirdiTheme) => css`
  ${buttonBlue(theme, { baseSize: 'sm' })};
  width: 6em;
`;
const clearButtonStyle = (theme: BirdiTheme) => css`
  ${buttonBlue(theme, { baseSize: 'sm' })};
  background-color: ${theme.mono0};
  border: solid 1px ${theme.mono98};
  color: ${theme.mono98};
  width: 7em;
  &:active {
    color: ${theme.mono98};
  }
`;

const FileUpload = (props) => {
  const { imageUrl, uploading, image } = props;

  return (
    <div css={(theme) => css`
        position: relative;
        width: 100px;
        height: 80px;
        background-color: ${theme.mono30};
        border: solid 1px ${theme.mono50};
      `}
    >
      <div css={(theme: BirdiTheme) => css`
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        `}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt=""
            className="photo"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: contain;
            `}
          />
        )}
        {!imageUrl && uploading && <LoaderSVG css={css`stroke-width: 2px;`} />}
        {!imageUrl && !uploading && (image === 'logo') && <BirdiLogoNavySVG css={css`font-size: 80px;`} />}
        {!imageUrl && !uploading && (image === 'emailHeader') && <BirdiLogoNavySVG css={css`font-size: 80px;`} />}
      </div>
    </div>
  );
};

export const CustomisationBranding = (): React.ReactElement => {
  const dispatch = useDispatch();
  const meta = useMetaSelector();

  const logoFileInput = React.createRef();
  const emailHeaderFileInput = React.createRef();

  const [orgName, setOrgName] = useState('');
  const [toastOpen, setToastOpen] = React.useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploadingEmailHeader, setUploadingEmailHeader] = useState(false);

  useEffect(() => {
    setOrgName(meta.organisation.name);
  }, []);

  const updateFields = async (fieldsToUpdate: Fields) => {
    await organisationAPI.updateBranding(stroot('iy3ohb'), meta.organisation.id, fieldsToUpdate);
    dispatch(updateOrganisation(fieldsToUpdate));
  };

  const uploadLogo = async () => {
    const file = logoFileInput.current.files[0];
    setUploadingLogo(true);
    const upload = await uploadOne({
      ...fileToUppyOpts(file),
      public: true,
    });
    await updateFields({ logo: upload.uploadURL });
    setUploadingLogo(false);
  };
  const clearLogo = async () => {
    if (window.confirm('Are you sure you would like to remove the logo?')) {
      const fields = {
        logo: null,
        displayLogo: false,
      };
      await updateFields(fields);
    }
  };

  const uploadEmailHeader = async () => {
    const file = emailHeaderFileInput.current.files[0];
    setUploadingEmailHeader(true);
    const upload = await uploadOne({
      ...fileToUppyOpts(file),
      public: true,
    });
    await updateFields({ emailHeader: upload.uploadURL });
    setUploadingEmailHeader(false);
  };
  const clearEmailHeader = async () => {
    if (window.confirm('Are you sure you would like to remove the email header?')) {
      const fields = {
        emailHeader: null,
        displayEmailHeader: false,
      };
      await updateFields(fields);
    }
  };

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Customization</h2>
      </div>
      <div css={pageGraySection}>
        <p>Manage your workspace and brand settings.</p>
      </div>
      <div css={boxStyle}>
        <div css={firstRowStyle}>
          <div>
            Default your workspace name to be used in the top left
          </div>
          <Toggle
            checked={meta.organisation?.displayBranding}
            onChange={(check: boolean) => updateFields({ displayBranding: check })}
          />
        </div>
      </div>
      <div css={boxStyle}>
        <div css={firstRowStyle}>
          <div css={css`font-weight: bold`}>
            Logo
          </div>
          <Toggle
            checked={meta.organisation.displayLogo}
            onChange={(check: boolean) => updateFields({ displayLogo: check })}
            disabled={!meta.organisation.logo}
          />
        </div>
        <div>
          <p css={(theme) => css`color: ${theme.mono50}`}>
            Recommended: Dark font, 400x320px PNG with a transparent background
          </p>
          <div css={imageRowStyle}>
            <FileUpload
              imageUrl={meta.organisation?.logo}
              uploading={uploadingLogo}
              image="logo"
            />
            <label
              htmlFor="upload-logo"
              css={uploadButtonStyle}
            >
              Upload
              <input
                id="upload-logo"
                type="file"
                accept="image/*"
                onChange={uploadLogo}
                ref={logoFileInput}
                hidden
              />
            </label>
            <button
              type="button"
              css={clearButtonStyle}
              onClick={clearLogo}
              disabled={!meta.organisation.logo}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <div css={boxStyle}>
        <div css={firstRowStyle}>
          <div css={css`font-weight: bold`}>
            Email Header
          </div>
          <Toggle
            checked={meta.organisation.displayEmailHeader}
            onChange={(check: boolean) => updateFields({ displayEmailHeader: check })}
            disabled={!meta.organisation.emailHeader}
          />
        </div>
        <div>
          <p css={(theme) => css`color: ${theme.mono50}`}>
            Recommended resolution: Dark font, 400x320px
          </p>
          <div css={imageRowStyle}>
            <FileUpload
              imageUrl={meta.organisation?.emailHeader}
              uploading={uploadingEmailHeader}
              image="emailHeader"
            />
            <label
              htmlFor="upload-email-header"
              css={uploadButtonStyle}
            >
              Upload
              <input
                id="upload-email-header"
                type="file"
                accept="image/*"
                onChange={uploadEmailHeader}
                ref={emailHeaderFileInput}
                hidden
              />
            </label>
            <button
              type="button"
              css={clearButtonStyle}
              onClick={clearEmailHeader}
              disabled={!meta.organisation.emailHeader}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
