import {  withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  SideBar, SidebarLink, SideMenuHeader,
} from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import GeneralSVG from '@birdi/icons/menu-general.svg';
import PermissionsSVG from '@birdi/icons/menu-permissions.svg';
import WorkspacesSVG from '@birdi/icons/menu-workspaces.svg';
import BottomSection from './bottom-section';

const SideNavigationUserProfile = withRouter(({ history }) => {
  const meta = useMetaSelector();

  const { pathname } = history.location;
  const userScopeId = pathname.substring(pathname.lastIndexOf('/') + 1);

  return (
    <div css={css`position: relative;`}>
      <SideBar>
        <SideMenuHeader
          label="User Details"
          id="user-details"
          backToPage="/admin-users"
        />
        <SidebarLink to={`/profile-general/${userScopeId}`} match={history.location.pathname}>
          <GeneralSVG />
          <span>General</span>
        </SidebarLink>
        <SidebarLink to={`/profile-permissions/${userScopeId}`} match={history.location.pathname}>
          <PermissionsSVG />
          <span>Permissions</span>
        </SidebarLink>
        {/* <SidebarLink to={`/profile-workspaces/${userScopeId}`} match={history.location.pathname}>
          <WorkspacesSVG />
          <span>Workspaces</span>
        </SidebarLink> */}
        <div css={css`height: 7em`} />
      </SideBar>
      <BottomSection />
    </div>
  )
});

export default SideNavigationUserProfile;
