import { stroot, user } from '@birdi/js-sdk';
import {
  FETCHING, UPDATE_FIELD, UPDATING,
  UPDATED, FETCH,
} from './reducer';
import * as metaActions from '../meta/reducer';

export const getProfile = () => (dispatch) => {
  dispatch({ type: FETCHING });
  return user.getSelf(stroot('ge9roo'))
    .then((response) => dispatch({ type: FETCH, payload: response.body }))
    .catch((err) => console.log(err)); // TODO: Log application area
};

export const updateOrganisation = (organisation) => (dispatch) => {
  dispatch({ type: UPDATE_FIELD, payload: organisation });
};

export const updateStateAndPatch = (field) => (dispatch) => {
  dispatch({ type: UPDATING });
  dispatch({ type: UPDATE_FIELD, payload: field });
  return user.update(stroot('ohp4om'), 'me', field)
    .then((response) => {
      dispatch({ type: UPDATED, payload: response.body });
      dispatch({ type: metaActions.UPDATE, payload: field });
    })
    .catch((err) => console.log(err)); // TODO: Log application area
};

export const updateAvatarAndPatch = (field) => (dispatch) => {
  dispatch({ type: UPDATING });
  dispatch({ type: UPDATE_FIELD, payload: field });
  return user.update(stroot('ohp4om'), 'me', field)
    .then((response) => {
      dispatch({ type: UPDATED, payload: response.body });
      dispatch({ type: metaActions.UPDATE, payload: field });
      return response;
    })
    .catch((err) => {
      console.error(err);
      throw err;
    });
};

export const updateField = (obj) => (dispatch) => dispatch({ type: UPDATE_FIELD, payload: obj });
