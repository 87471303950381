/* global fetch */

import { config } from '../config';
import { headers, stleaf, fetchAPI, SpanTag, APIResponse } from '../helpers';

export type MissionShareRole = 'view' | 'edit' | 'inspect' | 'qa' | 'partner';

export interface MissionSharingContextUser {
  id: string | null;
  pendingEmail: string | null;
  email: string | null;
  sharedMissionId: string;
  fname: string | null;
  lname: string | null;
  role: MissionShareRole;
  readOnly: boolean;
  position?: string;
}

export interface MissionSharingContext {
  missionName: string;
  users: MissionSharingContextUser[];
  privateSharing: boolean;
  adminSharing: boolean;
}

export const getContext = (st: SpanTag, id: string): Promise<APIResponse<MissionSharingContext>> => {
  const url = `${config.API_URL}/v2/share/mission/${id}`;
  return fetchAPI(stleaf(st, 'eigh7u'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

interface AddSharedUserOptsCommon {
  role: MissionShareRole;
  readOnly?: boolean;
  message?: string;
}

type AddSharedUserOpts = ({ scopeId: string; } | { email: string; }) & AddSharedUserOptsCommon;

export const addUser = (st: SpanTag, id: string, options: AddSharedUserOpts): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/share/mission/${id}`;
  return fetchAPI(stleaf(st, 'otah1a'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(options),
  });
};

export interface AddUserMultiBody {
  users: Array<{ email: string; } | { scopeId: string; }>;
  role: MissionShareRole;
  message?: string;
  readOnly?: boolean;
}

interface AddUserMultiResponseCommon {
  role: MissionShareRole;
  readOnly?: boolean;
  sharedMissionId: string;
}

export type AddUserMultiResponse = (
  { pendingEmail: string; } |
  { email: string; fname: string; lname: string; position?: string; }
) & AddUserMultiResponseCommon;

export const addUserMulti = (st: SpanTag, missionId: string, body: AddUserMultiBody): Promise<APIResponse<AddUserMultiResponse[]>> => {
  const url = `${config.API_URL}/v2/share/mission/${missionId}/users`;
  return fetchAPI(stleaf(st, 'ofixa6'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(body),
  });
}

interface UpdateRoleOpts {
  role: MissionShareRole;
}

export const updateRole = (st: SpanTag, sharedMissionId: string, obj: UpdateRoleOpts): Promise<APIResponse>=> {
  const url = `${config.API_URL}/v2/share/mission/${sharedMissionId}/role`;
  return fetchAPI(stleaf(st, 'aqu5ke'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(obj),
  });
};

export const deleteUser = (st: SpanTag, id: string) => {
  const url = `${config.API_URL}/v2/share/mission/${id}`;
  return fetchAPI(stleaf(st, 'eec5ea'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};
