import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import NotFoundIcon1 from '@birdi/icons/2024-not-found-1.svg';
import NotFoundIcon2 from '@birdi/icons/2024-not-found-2.svg';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { breakpoints } from '@birdi/theme/variables';

export const NotFoundScreen = (): JSX.Element => (
  <div css={(theme: BirdiTheme) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: ${theme.lightOcean};
    `}
  >
    <h1 css={(theme: BirdiTheme) => css`
      font-size: 2.25em;
      font-weight: 600;
      color: ${theme.midnight};
      margin: 0;
      `}
    >
      Uh oh!
    </h1>
    <div css={css`position: relative`}>
      <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          svg { font-size: 15em; }
          @media (max-width: ${breakpoints.xs}) {
            svg { font-size: 10em; }
          };
        `}
      >
        <NotFoundIcon1 />
        <NotFoundIcon2 />
      </div>
      <div css={css`
          position: absolute;
          top: 1.5em;
          right: 3em;
          @media (max-width: ${breakpoints.xs}) {
            top: 0.5em;
            right: 1.5em;
          };
        `}
      >
        <p>You are <span css={css`text-decoration: line-through;`}>here</span> lost</p>
      </div>
    </div>
    <p css={css`
      font-size: 1.2em;
      margin: 1em 0 1.5em 0;
      text-align: center;
      width: 20em;
      @media (max-width: ${breakpoints.xs}) {
        width: 16em;
      };
      `}
    >
      The page you are looking for either doesn&apos;t exist or has changed coordinates.
    </p>
    <Link
      to="/"
      css={(theme: BirdiTheme) => css`
        ${buttonBlue(theme)};
        padding: 0 1.5em;
        border-radius: 8px;
      `}
    >
      <span css={(theme: BirdiTheme) => css`color: ${theme.mono0}`}>
        Head back home
      </span>
    </Link>
  </div>
);
