import React from 'react';
import { css } from '@emotion/react';
import { DashboardMissions } from './dashboard-missions';
import { GetStarted } from './get-started';
import { useMetaSelector } from '../meta/reducer';

const MAX_WIDTH = '414px';

export const Dashboard: React.FC = () => {
  const meta = useMetaSelector();
  return (
    <div css={css`
        box-sizing: border-box;
        /* padding: 1rem; */
        width: 100%;
        /* @media (max-width: ${MAX_WIDTH}){
          padding: 1rem 1.2rem;
        } */
      `}
    >
      <GetStarted />
      <DashboardMissions />
      {/* {meta.permissions.includes('org:default') && (<DashboardMissions />)} */}
    </div>
  );
};
