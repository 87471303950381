export const breakpoints = {
  xs: '650px',
  s: '960px',
  m: '1024px',
  l: '1440px',
};

export const fonts = {
  body: `ProximaNova, -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
  Fira Sans, Droid Sans, 'Helvetica Neue', sans-serif`,
};

export const borderRadius = '2px';

export const shadows = {
  base: '1px 4px 4px hsla(0, 0%, 50%, 0.1)',
  header: 'rgba(17, 17, 17, 0.06) 0px 2px 3px 0px',
};
