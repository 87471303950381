import React from 'react';
import { css } from '@emotion/react';
import { BirdiTheme, breakpoints, fonts } from '@birdi/theme';
import { Link, LinkProps } from 'react-router-dom';
import BackSVG from '@birdi/icons/back.svg';
import { useHistory } from 'react-router-dom';

export const dividerStyle = (theme: BirdiTheme) => css`
  width: cal(100% - 2em);
  height: 1px;
  background-color: ${theme.mono20};
  margin: 0.25em;
`;

const linkStyle = css`
  width: cal(100% - 2em);
  border-radius: 7px;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  svg { margin-right: 0.5em }
`;

export const externalLinkStyle = (theme: BirdiTheme) => css`
  ${linkStyle};
  color: ${theme.midnight};
  &:hover {
    background-color: ${theme.sand};
  }
`;

export const TitleHolder = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75em;
  padding: 0.75em 0.25em;
`;
export const iconStyle = css`
  width: 1em;
  height: 1em;
`;
export const TitleStyle = css`
  font-weight: bold;
`;
export const poweredByBirdiStyle = (theme: BirdiTheme) => css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.5em;
  z-index: 3;
  background-color: ${theme.mono0};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const externalLinksHolder = (theme: BirdiTheme) => css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 85%;
  z-index: 3;
  padding: 1em 0.5em;
  margin: 0 0.5em;
  background-color: ${theme.mono0};
`;

export const backButtonStyle = (theme: BirdiTheme) => css`
  display: flex;
  align-items: center;
  color: inherit;
  padding: 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  &:hover {
    background: ${theme.lightOcean};
  }
`;

export const BackButton = ({ label, id } : { label: string, id: string }) => (
  <>
    <Link
      css={backButtonStyle}
      to="/"
    >
      <BackSVG css={css`margin-right: 1em;`} />
      <span id={id}>{label}</span>
    </Link>
  </>
);

export const BackButtonToPrev = ({ label, id } : { label: string, id: string }) => {
  const history = useHistory();
  return (
    <>
      <button
        css={backButtonStyle}
        onClick={() => history.goBack()}
      >
        <BackSVG css={css`margin-right: 1em;`} />
        <span id={id}>{label}</span>
      </button>
    </>
  );
}

export const BackButtonV2 = ({ backToPage } : { backToPage: string }) => {
  const history = useHistory();
  return (
    <button
      css={(theme: BirdiTheme) => css`
        padding-left: 0.75em;
        padding-right: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        border: solid 1px ${theme.mono30};
        border-radius: 5px;
        gap: 0.5em;
        height: 2.5em;
        box-sizing: border-box;
      `}
      onClick={() => {
        history.push(backToPage);
      }}
    >
      <BackSVG />
      <span>Back</span>
    </button>
  );
}

export const SideMenuHeader = ({ label, id, backToPage = '/', newStyles } : { label: string, id: string, backToPage?: string, newStyles?: null }) => {
  //
  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 0.5em;
      margin-bottom: 0.5em;
      // top section height is 1em(margin-top) + 2.5em(button) + 0.5em(margin-bottom) = 4em
    `}>
      <BackButtonV2 backToPage={backToPage} />
      <div css={(theme: BirdiTheme) => css`
        background-color: ${theme.mono20};
        padding-left: 1em;
        width: calc(100% + 1em);
        min-height: 3.5em;
        margin-left: -1em;
        font-weight: bold;
        display: flex;
        align-items: center;
        ${newStyles}
      `}>
        <span id={id} css={css`margin-left: 0.5em`}>{label}</span>
      </div>
    </div>
  );
}

export const SideBar = ({ children }) => (
  <div css={(theme: BirdiTheme) => css`
      overflow-y: auto;
      font-family: ${fonts.display};
      position: relative;
      display: none;
      box-sizing: border-box;
      flex-shrink: 0;
      z-index: 2;
      width: 100%;
      height: calc(100vh - 80px);
      padding: 1em;
      padding-bottom: 3.5em;
      .item {
        display: flex;
        width: 100%;
        gap: 1rem;
        color: ${theme.displayFont};
        padding: 0.5em;
        font-size: 0.9375em;
        align-items: center;
        border-radius: 0.625em;
        box-sizing: border-box;
        .icon-chevron {
          margin-left: auto;
        }
        svg {
          font-size: 1.5em;
        }
        .icon-external {
          font-size: 1em;
        }
        &.selected {
          color: ${theme.deepOcean};
        }
        &:hover {
          background-color: ${theme.mono20};
        }
        &.main {
          font-size: 1em;
          font-weight: 700;
        }
        &.sub {
          padding-left: 3rem;
        }
        &.link-disabled {
          pointer-events: none;
          color: ${theme.mono40};
        }
      }
      .divider {
        border-top: 2px solid ${theme.mono20};
      }
      @media (min-width: ${breakpoints.m}) {
        display: block;
      }
    `}
  >
    {children}
  </div>
);

export function SidebarLink({
  to, match, children, disabled=false, id=null, dashboard=false,
}) {
  const otherProps: Partial<LinkProps> = {};
  if (id) otherProps.id = id;
  if (disabled) {
    return (
      <div css={(theme) => css`
        ${linkStyle};
        color: ${theme.mono40};
        padding-left: ${dashboard ? '2em' : '0.5em'}
      `}
      >
        {children}
      </div>
    )
  } else {
    return (
      <Link
        to={to}
        {...otherProps}
      >
        <div css={(theme) => css`
          ${linkStyle};
          color: ${theme.midnight};
          background-color: ${to === match ? theme.lightOcean : theme.mono0};
          &:hover {
            background-color: ${to !== match ? theme.sand : theme.lightOcean};
          }
          padding-left: ${dashboard ? '2em' : '0.5em'};
        `}
        >
          {children}
        </div>
      </Link>
    );
  }
}

export function SidebarExternalLink({
  to, children,
}) {
  //
  return (
    <Link
      to={to}
      target="_blank"
    >
      <div css={externalLinkStyle}>
        {children}
      </div>
    </Link>
  );
}

export function TopMenuButton({
  link, label, closeMenu, disabled=false,
}) {
  const history = useHistory();
  return (
    <button
      type="button"
      disabled={disabled}
      css={(theme) => css`
      color: ${disabled ? theme.mono30 : theme.midnight};
      padding: 0.5em;
      width: 100%;
      text-align: left;
      margin: 0;
      border-radius: 3px;
      &:hover {
        background: ${disabled ? theme.mono0 : theme.lightOcean};
      }
    `}
      onClick={() => {
        history.push(link);
        closeMenu();
      }}
    >
      {label}
    </button>
  );
}
