import { css } from '@emotion/react';
import { BirdiTheme } from './theme';

type BaseSize = 'md' | 'sm';
const baseSizes: Record<BaseSize, string> = {
  md: 'height: 3rem;',
  sm: 'height: 2rem;',
}
interface ButtonOpts {
  baseSize?: BaseSize;
  fullWidth?: boolean;
}

const defaultButtonOpts: ButtonOpts = { baseSize: 'md', fullWidth: false };

const baseStyle = (theme: BirdiTheme, opts: ButtonOpts = defaultButtonOpts) => css`
  ${opts.baseSize ? baseSizes[opts.baseSize] : ''};
  ${opts.fullWidth && 'width: 100%;'};
  border-radius: 2px;
  padding: 0 0.75rem;
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover:enabled {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  &:disabled {
    cursor: not-allowed;
    background: ${theme.mono40};
    color: ${theme.mono0};
    border: none;
  }
  &:focus {
    outline: 2px solid ${theme.mono40};
  }
`

export const buttonBlue = (theme: BirdiTheme, opts: ButtonOpts = defaultButtonOpts) => css`
  ${baseStyle(theme, opts)};
  background: ${theme.deepOcean};
  color: ${theme.mono0};
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    color: ${theme.mono0};
  }
`;
export const buttonOutlineBlue = (theme: BirdiTheme, opts: ButtonOpts = defaultButtonOpts) => css`
  ${baseStyle(theme, opts)};
  color: ${theme.deepOcean};
  border: 1px solid ${theme.deepOcean};
  background: transparent;
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    color: ${theme.deepOcean};
  }
  &:visited {
    color: ${theme.deepOcean};
  }
`;

export const buttonOutlineBlack = (theme: BirdiTheme, opts: ButtonOpts = defaultButtonOpts) => css`
  ${baseStyle(theme, opts)};
  color: ${theme.midnight};
  border: 1px solid ${theme.midnight};
  background: transparent;
`;

export const buttonGrey = (theme: BirdiTheme, opts: ButtonOpts = defaultButtonOpts) => css`
  ${baseStyle(theme, opts)};
  color: ${theme.midnight};
  border: 1px solid ${theme.midnight};
  background: ${theme.mono30};
`;

export const buttonTransparent = (theme: BirdiTheme, opts: ButtonOpts = defaultButtonOpts) => css`
  ${baseStyle(theme, opts)};
  background: transparent;
  color: ${theme.mono70};
  &:hover:enabled {
    background: ${theme.mono20};
    transform: translateY(0);
    box-shadow: none;
  }
  &:disabled {
    background: transparent;
    color: ${theme.mono40};
    border: none;
  }
`;
