import { css } from "@emotion/react";

export const BetaBadge = () => (
  <span
    css={(theme) => css`
      display: inline-block;
      background: ${theme.lightOcean};
      color: ${theme.ocean};
      border-radius: 8px;
      padding: 0 4px;
      font-size: 14px;
      font-weight: 400;
    `}
    aria-label="(in beta)"
  >
    🚀 Beta
  </span>
);
