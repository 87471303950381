import React, { useEffect, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { useMetaSelector } from '../meta/reducer';

export const Security = withRouter(({ match }): React.ReactElement => {
  const meta = useMetaSelector();

  useEffect(() => {
    //
  }, []);

  return (
    <div>
      <h2 css={css`margin: 1em`}>Security</h2>
      <div css={css`padding: 1em`}>
        <p css={(theme) => css`color: ${theme.flora}; font-weight: bold`}>Coming Soon ...</p>
      </div>
    </div>
  );
});
