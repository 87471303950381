import {  withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  SideBar, SidebarLink, SideMenuHeader,
} from './side-bar-utils';
import { useMetaSelector } from '../../meta/reducer';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import BottomSection from './bottom-section';

const SideNavigationInspectionTools = withRouter(({ history }) => {
  const meta = useMetaSelector();
  const inspection = meta.orgFeatureFlags.includes(FeatureFlagNames.PowercorInspectionDashboard)
    && meta.permissions.includes('org:manage-missions');
  return (
    <div css={css`position: relative;`}>
      <SideBar>
        <SideMenuHeader
          label="Inspection Tools"
          id="inspection-tools"
        />
        <SidebarLink to="/assessment-types" match={history.location.pathname}>
          <span>Assessment Types</span>
        </SidebarLink>
        <SidebarLink to="/assessment-severity" match={history.location.pathname}>
          <span>Assessment Severity</span>
        </SidebarLink>
        <SidebarLink to={inspection ? '/inspections' : '#'} match={history.location.pathname} disabled={!inspection}>
          <span id="inspection-summary">Inspection Summary</span>
        </SidebarLink>
        <div css={css`height: 7em`} />
      </SideBar>
      <BottomSection />
    </div>
  )
});

export default SideNavigationInspectionTools;
