import React, { useEffect, useState } from 'react';
import { stroot } from '@birdi/js-sdk';
import { processingJobs as processingJobsAPI } from '@birdi/js-sdk/src/public';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { css } from '@emotion/react';
import { input, separator2 } from '@birdi/theme/blocks';
import { JobTable } from './JobTable';
import { Pagination } from '@birdi/pagination';
import { formating } from './utils';
import { downloadContent, objectToCsv } from '../common/utils.js';
import { BirdiDatePicker } from '@birdi/date-picker';
import { breakpoints } from '@birdi/theme';
import { useMetaSelector } from '../meta/reducer';
import { pageTitleHolder, pageTitle, pageGraySection } from '@birdi/theme/blocks';
import { buttonOutlineBlack } from '@birdi/theme';
import SearchSVG from '@birdi/icons/search.svg';

const LOAD_AMOUNT = 20;

interface Filters {
  searchTerm: string;
  dates: [string, string] | null,
}

export const ProcessingJobs: React.FC<RouteComponentProps & {}> = (props) => {
  const meta = useMetaSelector();

  const [jobs, setJobs] = useState(null);
  const [filters, setFilters] = useState<Filters>({
    searchTerm: '', dates: null,
  });

  const [start, setStart] = useState(1);
  const [count, setCount] = useState(0);

  const searchJobs = (filters: Filters, start: number = 1) => {
    processingJobsAPI.getJobs(stroot('mool3u'), {
      start,
      limit: LOAD_AMOUNT,
      mapName: filters.searchTerm,
      ...(filters.dates && { dates: filters.dates }),
    })
      .then((res) => {
        setJobs(res.body.items);
        setCount(res.body.count);
      });
  }

  useEffect(() => {
    searchJobs(filters);
  }, []);

  const updatePage = (page: number) => {
    setStart(page);
    searchJobs(filters, page);
  };

  const updateFilters = (updatedFilter: Partial<Filters>) => {
    setFilters({ ...filters, ...updatedFilter });
    setStart(1);
    searchJobs({ ...filters, ...updatedFilter });
  }

  const downloadData = async () => {
    const headers = [
      { label: 'Map Name', key: 'mapName' },
      { label: 'Status', key: 'status' },
      { label: 'GP', key: 'gp' },
      { label: 'Requested By', key: 'requestedBy' },
      { label: 'Created Date', key: 'createdDate' },
      // {...(meta.organisation.plan === 'pro') && {
      //   label: 'GCP requested',
      //   key: 'gcp',
      // }},
    ];
    if (meta.organisation.plan !== 'enterprise') {
      headers.splice(3, 0, { label: 'Cost', key: 'cost' });
    }
    if (jobs && jobs.length > 0) {
      const data = jobs.map((job) => formating(job));
      const result = await objectToCsv(headers, data);
      downloadContent('processing_jobs.csv', result, 'text/csv');
    };
  };

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>Processing</h2>
      </div>
      <div css={(theme) => css`
        ${pageGraySection(theme)};
        justify-content: space-between;
        position: relative;
        // z-index: 3;
        @media (max-width: ${breakpoints.xs}) {
          padding-bottom: 1em;
          p { margin-bottom: 0 }
        }
      `}>
        <p>View, filter and export your processing jobs.</p>
        {count > 0 ? (
          <button
            type="button"
            css={(theme) => css`
              ${buttonOutlineBlack(theme)};
              background-color: ${theme.mono0};
              border: 1px solid ${theme.mono40};
              border-radius: 4px;
              height: 2em;
              span {
                color: ${theme.darkGrey};
              }
            `}
            disabled={!jobs || jobs.length === 0}
            onClick={async () => {
              await downloadData();
            }}
          >
            <span>Export</span>
          </button>
        ) : (<></>)}
      </div>

      <div css={(theme) => css`${separator2(theme)}`}>
        <form
          role="search"
          onSubmit={(event) => { event.preventDefault() }}
          css={css`
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            & > *:not(last-child) {
              margin-right: 0.5em;
            };
            gap: 0.5em;
            @media (max-width: ${breakpoints.xs}) {
              flex-direction: column;
            };
          `}
        >
          <div
            css={css`
              position: relative;
              display: inline-block;
            `}
          >
            <SearchSVG
              css={(theme) => css`
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                pointer-events: none;
                height: 1em;
                width: 1em;
                color: ${theme.mono40}
              `}
            />
            <input
              id="mapName"
              type="text"
              value={filters.searchTerm}
              placeholder="Search by name"
              onChange={(event) => updateFilters({ searchTerm: event.target.value })}
              css={(theme) => css`
                ${input(theme)};
                // border: 1px solid #ccc !important;
                min-width: 20em;
                padding-left: 2em;
                &::placeholder {
                  font-size: 1em;
                }
              `}
            />
          </div>
          <BirdiDatePicker
            type="range"
            onChange={(dates) => updateFilters({ dates: dates })}
            defaultStartDate={null}
            defaultEndDate={null}
            placeholder="Filter by created dates"
          />
        </form>
      </div>

      <section css={css`padding: 1em 1em 2em 1em;`}>
        <JobTable jobs={jobs} />
        <div css={css`padding: 1em 0`}>
          <Pagination
            onChange={(currentPage) => updatePage(currentPage)}
            itemCount={count}
            itemsPerPage={LOAD_AMOUNT}
            currentPage={start}
          />
        </div>
      </section>
    </div>
  );
}

export default withRouter(ProcessingJobs);
