import React, { useEffect, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { useMetaSelector } from '../meta/reducer';

export const Workspaces = withRouter(({ match }): React.ReactElement => {
  const meta = useMetaSelector();

  useEffect(() => {
    //
  }, []);

  return (
    <div>
      <h2 css={css`margin: 1em`}>Workspaces</h2>
      {/* <div css={css`padding: 1em`}>
        New side menu !!
      </div> */}
    </div>
  );
});
