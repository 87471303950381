import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider, useInfiniteQuery } from 'react-query';
import { stroot, mission as missionAPI } from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { pageDescription } from '@birdi/theme/blocks';
import { buttonOutlineBlue } from '@birdi/theme';
import { MissionList } from './List';
import { Mission } from '@birdi/types/mission';
import { MapDashboardHeader } from '../common/map-dashboard-header/';

const queryClient = new QueryClient();

const LOAD_AMOUNT = 12;

function Maps() {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    'missionsData',
    ({ pageParam = 1 }) => {
      return missionAPI.listShared(stroot('je6ook'), {
        start: pageParam,
        limit: LOAD_AMOUNT,
      }).then((res) => res.body?.items || []);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length === LOAD_AMOUNT ? allPages.length + 1 : undefined;
      },
      keepPreviousData: true,
    }
  );

  const handleLoadMore = () => {
    fetchNextPage();
  };

  const attachCoverImagesToMaps = (missionId: string, url: string) => {
    const existingData = queryClient.getQueryData<{ pages: Mission[][] }>('missionsData')!;
    const updatedData = {
      ...existingData,
      pages: existingData.pages.map((page) =>
        page.map((mission) =>
            mission.id === missionId ? { ...mission, coverImageURL: url } : mission
        )
      )
    };
    queryClient.setQueryData('missionsData', updatedData);
  };


  const getCover = async (missionId: string) => {
    const res = await missionAPI.getCover(stroot('ioz1sh'), missionId);
    const coverImageURL = res.body?.url || '';
    attachCoverImagesToMaps(missionId, coverImageURL);
  }

  useEffect(() => {
    // Fetch cover images when the data changes
    if (data) {
      data.pages.forEach(pageData => {
        pageData.forEach((mission) => {
          if (!mission.coverImageURL) {
            getCover(mission.id);
          }
        });
      });
    }
  }, [data]);

  return (
    <div css={css`
        box-sizing: border-box;
        padding: 1rem 1.5rem;
        width: 100%;
      `}
    >
      {data && data.pages.map((pageData, pageIndex) => (
        <div key={pageIndex}>
          <MissionList
            missions={pageData}
          />
        </div>
      ))}
      {hasNextPage ? (
        isFetchingNextPage ? (
          <span>Loading more...</span>
        ) : (
          <button
            css={(theme) => css`${buttonOutlineBlue(theme)}; width: 100%;`}
            onClick={handleLoadMore}
          >
            Load More ...
          </button>
        )
      ) : null}
      {data && data.pages[0].length === 0 && (
        <p css={css`width: calc(100% - 1rem);`}>You have no shared maps.</p>
      )}
    </div>
  )
}

export const SharedMissions = () => (
  <div>
    <MapDashboardHeader
      titleComponent={(<h2 css={css`margin: 0`}>Shared with me</h2>)}
    />
    <div css={pageDescription}>
      <p>These are maps that have been shared with you directly.</p>
    </div>
    <QueryClientProvider client={queryClient}>
      <Maps />
    </QueryClientProvider>
  </div>
);
