import React, { useEffect, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { pageTitleHolder, pageTitle, pageGraySection } from '@birdi/theme/blocks';
import { stroot, organisation as organisationAPI } from '@birdi/js-sdk';
import { useMetaSelector, updateOrganisation } from '../meta/reducer';
import { useDispatch } from 'react-redux';
import { textInput } from '@birdi/theme/form';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import * as Toast from '@radix-ui/react-toast';
import * as styles from './styles';
import HandsSVG from '@birdi/icons/thank-you.svg';

interface Fields {
  name: string;
  url: string;
}

export const General = withRouter(({ match }): React.ReactElement => {
  const dispatch = useDispatch();
  const meta = useMetaSelector();
  const [orgName, setOrgName] = useState('');
  const [orgUrl, setOrgUrl] = useState('');
  const [toastOpen, setToastOpen] = React.useState(false);

  useEffect(() => {
    setOrgName(meta.organisation.name);
  }, []);

  const updateFields = async (fieldsToUpdate: Fields) => {
    await organisationAPI.updateBranding(stroot('iy3ohb'), meta.organisation.id, fieldsToUpdate);
    dispatch(updateOrganisation(fieldsToUpdate));
  };

  return (
    <div>
      <div css={pageTitleHolder}>
        <h2 css={pageTitle}>General</h2>
      </div>
      <div css={pageGraySection}>
        <p>Manage your organization information.</p>
      </div>
      <div css={css`padding: 1em; max-width: 35em`}>
        <p>Organization Name (Billing Name)</p>
        <div css={css`display: flex; flex-direction: row`}>
          <input
            css={(theme) => css`
              ${textInput(theme)};
              margin-right: 1em;
              max-width: 25em
            `}
            type="text"
            id="orgName"
            defaultValue={orgName || meta.organisation.name}
            onChange={(event) => setOrgName(event.target.value)}
          />
        </div>
        {/* <p css={(theme) => css`color: ${theme.flora}; font-weight: bold`}>Coming Soon ...</p>
        <div>
          <p css={(theme) => css`color: ${theme.mono40}; margin-top: 0`}>Organisation URL (Web address)</p>
          <div css={css`display: flex; flex-direction: column`}>
            <div css={css`display: flex; flex-direction: row;`}>
              <input
                disabled
                css={(theme) => css`
                  ${textInput(theme)};
                  margin-right: 0.25em;
                  margin-bottom: 1em;
                  max-width: 25em;
                `}
                type="text"
                id="orgUrl"
                // defaultValue={orgUrl || meta.organisation.url}
                // onChange={(event) => setOrgUrl(event.target.value)}
              />
              <p css={(theme) => css`color: ${theme.mono40}`}>.birdi.io</p>
            </div>
          </div>
        </div> */}
        <Toast.Provider>
          <button
            type="button"
            css={(theme) => css`
              ${buttonBlue(theme)};
              height: 2.5em;
              margin-top: 1em;
            `}
            onClick={() => {
              updateFields({ name: orgName });
              setToastOpen(true);
              window.setTimeout(() => setToastOpen(false), 2000);
            }}
            disabled={!orgName || meta.organisation.name === orgName}
          >
            Save changes
          </button>
          <Toast.Root css={styles.toastRoot} open={toastOpen}>
            <Toast.Description css={styles.toastContent}>
              <HandsSVG />
              Organisation name updated
            </Toast.Description>
          </Toast.Root>
          <Toast.Viewport css={styles.toastViewport} />
        </Toast.Provider>
      </div>
    </div>
  );
});
